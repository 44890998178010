import { useState } from 'react';
import { ClockIcon, Trash } from 'lucide-react';
import { Thread } from '../../api/generated-client';
import { useNavigate } from 'react-router-dom';
import { formatTimeDifference } from '../../utils/classnames';
import ConfirmationDialog from 'components/ui/ConfirmationDialog';

interface ThreadCardProps {
    thread: Thread;
    onDelete: (threadId: string) => Promise<void>;
}

const ThreadCard = ({ thread, onDelete }: ThreadCardProps) => {
    const navigate = useNavigate();
    const [isDeleting, setIsDeleting] = useState(false);
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

    const handleDelete = async () => {
        if (!thread.id) return;
        setIsDeleting(true);
        try {
            await onDelete(thread.id);
        } catch (error) {
            console.error('Failed to delete thread:', error);
            setIsDeleting(false);
        }
        setShowDeleteConfirm(false);
    };

    return (
        <div className="flex flex-col space-y-4 py-6 border-b border-dark-200 last:border-b-0">
            <button
                onClick={() => navigate(`/chat/${thread.id}`)}
                className="text-white lg:text-xl font-medium truncate transition 
                 duration-200 hover:text-blue-400 cursor-pointer text-left"
            >
                {thread.title || 'New Chat'}
            </button>

            <div className="flex flex-row items-center justify-between w-full">
                <div className="flex flex-row items-center space-x-1.5 text-white/70">
                    <ClockIcon size={15} />
                    <p className="text-xs">
                        {formatTimeDifference(new Date(), thread.created ?? '')} ago
                    </p>
                </div>

                <button
                    onClick={() => setShowDeleteConfirm(true)}
                    disabled={isDeleting}
                    className="relative text-red-400 hover:text-red-500 transition duration-200 
                   disabled:opacity-50 disabled:cursor-not-allowed group"
                >
                    {isDeleting ? (
                        <div className="w-[17px] h-[17px] border-2 border-red-400 border-t-transparent 
                        rounded-full animate-spin" />
                    ) : (
                        <>
                            <Trash size={17} />
                            <span className="absolute -top-8 left-1/2 -translate-x-1/2 px-2 py-1 
                           bg-dark-100 text-white text-xs rounded opacity-0 
                           group-hover:opacity-100 transition-opacity whitespace-nowrap">
                                Delete thread
                            </span>
                        </>
                    )}
                </button>
            </div>

            <ConfirmationDialog
                isOpen={showDeleteConfirm}
                title="Delete Thread"
                message="Are you sure you want to delete this thread? This action cannot be undone."
                confirmLabel="Delete"
                isConfirming={isDeleting}
                confirmingLabel="Deleting..."
                onClose={() => setShowDeleteConfirm(false)}
                onConfirm={handleDelete}
                isDanger
            />
        </div>
    );
};

export default ThreadCard;