// src/components/auth/OtpForm.tsx
import React, { useRef, useEffect } from 'react';

interface OtpFormProps {
    email: string;
    onSubmit: (otp: string) => void;
    onBack: () => void;
    isLoading: boolean;
    error?: string | null;
}

export const OtpForm: React.FC<OtpFormProps> = ({
    email,
    onSubmit,
    onBack,
    isLoading,
    error,
}) => {
    const [otp, setOtp] = React.useState('');
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        inputRef.current?.focus();
    }, []);

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        onSubmit(otp);
    };

    return (
        <form onSubmit={handleSubmit} className="space-y-4">
            <div className="text-center">
                <h2 className="text-lg font-medium text-white mb-2">Enter verification code</h2>
                <p className="text-white/70 text-sm">
                    We've sent a code to {email}
                </p>
            </div>

            <div>
                <input
                    ref={inputRef}
                    type="text"
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                    className="w-full px-4 py-3 bg-dark-100/50 border border-dark-200 text-white rounded-lg
                    focus:outline-none focus:ring-2 focus:ring-blue-500/50 focus:border-transparent
                    placeholder:text-white/30 transition duration-200 text-center text-lg tracking-widest"
                    placeholder="Enter code"
                    maxLength={6}
                    required
                    disabled={isLoading}
                />
            </div>

            {error && (
                <div className="bg-red-500/10 border border-red-500/50 text-red-200 px-4 py-3 rounded-lg">
                    {error}
                </div>
            )}

            <div className="flex flex-col space-y-3">
                <button
                    type="submit"
                    disabled={isLoading}
                    className="w-full bg-blue-500 hover:bg-blue-600 focus:bg-blue-600 text-white py-3 px-4 rounded-lg
                   font-medium transition duration-200 focus:outline-none focus:ring-2 focus:ring-blue-500/50
                   focus:ring-offset-2 focus:ring-offset-dark-100 disabled:opacity-50 disabled:cursor-not-allowed"
                >
                    {isLoading ? (
                        <div className="flex items-center justify-center space-x-2">
                            <div className="w-5 h-5 border-2 border-white/30 border-t-white rounded-full animate-spin" />
                            <span>Verifying...</span>
                        </div>
                    ) : (
                        'Verify'
                    )}
                </button>

                <button
                    type="button"
                    onClick={onBack}
                    disabled={isLoading}
                    className="text-white/70 hover:text-white transition-colors"
                >
                    Use different email
                </button>
            </div>
        </form>
    );
};