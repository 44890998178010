// src/components/auth/AuthFlow.tsx
import React from 'react';
import { useAuthFlow, AuthStep } from '../../hooks/useAuthFlow';
import { EmailForm } from './EmailForm';
import { OtpForm } from './OtpForm';

interface AuthFlowProps {
    onSuccess?: () => void;
    onError?: (error: string) => void;
}

export const AuthFlow: React.FC<AuthFlowProps> = ({ onSuccess, onError }) => {
    const {
        currentStep,
        email,
        isLoading,
        error,
        handleEmailSubmit,
        handleOtpSubmit,
        resetFlow,
    } = useAuthFlow({ onSuccess, onError });

    const renderStep = (step: AuthStep) => {
        switch (step) {
            case 'email':
                return (
                    <EmailForm
                        onSubmit={handleEmailSubmit}
                        isLoading={isLoading}
                        error={error}
                    />
                );
            case 'otp':
                return (
                    <OtpForm
                        email={email}
                        onSubmit={handleOtpSubmit}
                        onBack={resetFlow}
                        isLoading={isLoading}
                        error={error}
                    />
                );
            default:
                return null;
        }
    };

    return (
        <div className="bg-dark-secondary/80 backdrop-blur-md p-8 rounded-2xl shadow-2xl border border-dark-200">
            <div className="text-center mb-8">
                <h2 className="text-3xl font-bold text-white mb-2">AskPR Beta</h2>
                <p className="text-white/70">Please sign in to continue</p>
            </div>

            {renderStep(currentStep)}
        </div>
    );
};