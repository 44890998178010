import { ArrowRight } from 'lucide-react';
import { cn } from 'utils/classnames';
import { Icebreaker } from 'api/generated-client';

interface IcebreakerCardProps {
    icebreaker: Icebreaker;
    onSelect: (icebreaker: Icebreaker) => void;
    disabled?: boolean;
}

const IcebreakerCard = ({
    icebreaker,
    onSelect,
    disabled = false
}: IcebreakerCardProps) => {
    return (
        <button
            onClick={() => onSelect(icebreaker)}
            disabled={disabled}
            className={cn(
                "w-full group flex flex-col",
                "bg-dark-secondary hover:bg-dark-100",
                "border border-dark-200 hover:border-dark-100",
                "rounded-lg overflow-hidden transition-all duration-200",
                "hover:-translate-y-1 hover:shadow-lg",
                "disabled:opacity-50 disabled:cursor-not-allowed",
                "disabled:hover:translate-y-0 disabled:hover:shadow-none"
            )}
        >
            {/* Image Container with fixed 16:9 aspect ratio */}
            <div className="relative w-full pt-[56.25%]">
                <img
                    src={icebreaker.full_image || icebreaker.thumbnail || ''}
                    alt={icebreaker.title || 'Icebreaker'}
                    className={cn(
                        "absolute inset-0 w-full h-full",
                        "object-cover object-center", // This ensures the image covers the area and stays centered
                        "bg-dark-100" // Background color shows during image load
                    )}
                />
            </div>

            <div className="flex flex-col p-4 flex-1">
                <h3 className="text-white font-medium text-lg mb-2 line-clamp-2">
                    {icebreaker.title}
                </h3>
                {icebreaker.description && (
                    <p className="text-white/70 text-sm mb-4 line-clamp-5">
                        {icebreaker.description}
                    </p>
                )}
                <div className="mt-auto flex justify-end">
                    <div
                        className={cn(
                            "p-2 rounded-full",
                            "bg-dark-100 group-hover:bg-dark-200",
                            "transition-all duration-200"
                        )}
                    >
                        <ArrowRight
                            size={20}
                            className={cn(
                                "text-white/70 group-hover:text-white",
                                "transition-all duration-200",
                                "group-hover:translate-x-0.5"
                            )}
                        />
                    </div>
                </div>
            </div>
        </button>
    );
};

export default IcebreakerCard;