// src/components/chat/MessageBox.tsx
import Markdown from 'markdown-to-jsx';
import { Message, RoleEnum } from '../../api/generated-client';
import { SourceList } from './SourceList';
import { Disc3 } from 'lucide-react';
import { cn } from '../../utils/classnames';
import CopyIconButton from 'components/ui/CopyIconButton';

interface MessageBoxProps {
  message: Message;
  isLast: boolean;
  loading: boolean;
  onRewrite?: () => void;
}

const MessageBox = ({
  message,
  isLast,
  loading,
  onRewrite
}: MessageBoxProps) => {
  if (message.role === RoleEnum.User) {
    return (
      <div className="w-full pt-16">
        <h2 className="text-white font-medium text-3xl">
          {message.content}
        </h2>
      </div>
    );
  }

  return (
    <div className="flex flex-col space-y-9 lg:space-y-0 lg:flex-row lg:justify-between lg:space-x-9 py-3">
      <div className="flex flex-col space-y-6 w-full">
        {/* Sources Section */}
        {message.relevant_chunks && message.relevant_chunks.length > 0 && (
          <SourceList sources={message.relevant_chunks} />
        )}

        {/* Answer Section */}
        <div className="flex flex-col space-y-2">
          <div className="flex flex-row items-center space-x-2">
            <Disc3
              className={cn(
                "text-white",
                isLast && loading && "animate-spin"
              )}
              size={20}
            />
            <h3 className="text-white font-medium text-xl">Answer</h3>
          </div>
          <Markdown
            className="prose prose-invert prose-p:leading-relaxed prose-pre:p-0 max-w-none break-words font-medium text-white"
          >
            {message.content}
          </Markdown>

          {!loading && (
            <div className="flex flex-row items-center justify-end w-full py-4 -mx-2">
              <CopyIconButton content={message.content} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MessageBox;