import React, { useState, useEffect } from 'react';
import { useAuth } from '../context/AuthContext';
import { BookOpenText } from 'lucide-react';
import { Thread } from '../api/generated-client';
import ThreadCard from '../components/thread/ThreadCard';

const LibraryPage = () => {
    const { apis } = useAuth();
    const [threads, setThreads] = useState<Thread[]>([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const loadThreads = async () => {
            if (!apis?.chatsApi) return;

            try {
                const response = await apis.chatsApi.chatsThreadsList();
                setThreads(response.data.results || []);
            } catch (err) {
                console.error('Error loading threads:', err);
            } finally {
                setLoading(false);
            }
        };

        loadThreads();
    }, [apis?.chatsApi]);

    const handleDelete = async (threadId: string) => {
        if (!apis?.chatsApi) return;

        try {
            await apis.chatsApi.chatsThreadsDestroy(threadId);
            setThreads(threads.filter(thread => thread.id !== threadId));
        } catch (err) {
            console.error('Error deleting thread:', err);
            throw err; // Propagate error to ThreadCard component
        }
    };

    if (loading) {
        return (
            <div className="flex flex-row items-center justify-center min-h-screen">
                <div className="w-8 h-8 border-4 border-blue-500 border-t-transparent rounded-full animate-spin" />
            </div>
        );
    }

    return (
        <div>
            <div className="flex flex-col pt-4">
                <div className="flex items-center">
                    <BookOpenText />
                    <h1 className="text-3xl font-medium p-2 text-white">Library</h1>
                </div>
                <hr className="border-t border-dark-200 my-4 w-full" />
            </div>

            {threads.length === 0 ? (
                <div className="flex flex-row items-center justify-center min-h-[50vh]">
                    <p className="text-white/70 text-sm">No chats found.</p>
                </div>
            ) : (
                <div className="flex flex-col pb-20 lg:pb-2">
                    {threads.map((thread) => (
                        <ThreadCard
                            key={thread.id}
                            thread={thread}
                            onDelete={handleDelete}
                        />
                    ))}
                </div>
            )}
        </div>
    );
};

export default LibraryPage;