import React from 'react';
import { cn } from '../../utils/classnames';
import { Icebreaker } from 'api/generated-client';
import IcebreakerCapsule from './IceBreakerCapsule';

interface IcebreakersProps {
    icebreakers: Icebreaker[];
    isLoading: boolean;
    disabled?: boolean;
    onSelect: (icebreaker: Icebreaker) => void;
}

const LoadingSkeleton = () => (
    <div className="flex flex-wrap gap-2">
        {[...Array(4)].map((_, i) => (
            <div
                key={i}
                className={cn(
                    "h-8 rounded-full bg-dark-secondary animate-pulse",
                    "w-[160px] md:w-[200px]"
                )}
            />
        ))}
    </div>
);


const Icebreakers: React.FC<IcebreakersProps> = ({
    icebreakers,
    isLoading,
    disabled = false,
    onSelect,
}) => {
    if (isLoading) {
        return <LoadingSkeleton />;
    }

    if (!icebreakers.length) {
        return null;
    }

    return (
        <div className="flex flex-wrap gap-2">
            {icebreakers.map((icebreaker, index) => (
                <IcebreakerCapsule
                    key={index}
                    icebreaker={icebreaker}
                    disabled={disabled}
                    onSelect={onSelect}
                />
            ))}
        </div>
    );
};

export default Icebreakers;