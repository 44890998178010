// src/components/chat/MessageBoxLoading.tsx
const MessageBoxLoading = () => {
  return (
    <div className="flex flex-col space-y-2 w-full bg-dark-primary animate-pulse rounded-lg py-3">
      <div className="h-2 rounded-full w-full bg-dark-200" />
      <div className="h-2 rounded-full w-9/12 bg-dark-200" />
      <div className="h-2 rounded-full w-10/12 bg-dark-200" />
    </div>
  );
};

export default MessageBoxLoading;