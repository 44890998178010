import { useState } from 'react';
import { useAuth } from 'context/AuthContext';
import { LogOut, User, Check, X, PencilLine } from 'lucide-react';
import { Popover, PopoverButton, PopoverPanel, Transition } from '@headlessui/react';
import { LogoutDialog } from 'components/chat/NavBar/LogoutDialog';
import { User as UserType } from 'api/generated-client';
import { cn } from 'utils/classnames';

interface UserProfileMenuProps {
    placement?: 'sidebar' | 'navbar';
}

const UserProfileMenu = ({ placement = 'navbar' }: UserProfileMenuProps) => {
    const { apis, logout } = useAuth();
    const [showLogoutDialog, setShowLogoutDialog] = useState(false);
    const [userData, setUserData] = useState<UserType | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [editForm, setEditForm] = useState({
        firstName: '',
        lastName: ''
    });

    const handleMenuOpen = async () => {
        if (!apis?.accountsApi || userData) return;

        setIsLoading(true);
        try {
            const response = await apis.accountsApi.accountsUsersRetrieve('0');
            if (response.data) {
                setUserData(response.data);
                setEditForm({
                    firstName: response.data.first_name || '',
                    lastName: response.data.last_name || ''
                });
            }
        } catch (error) {
            console.error('Failed to fetch user data:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleSave = async () => {
        if (!apis?.accountsApi || !userData) return;

        setIsSaving(true);
        try {
            const response = await apis.accountsApi.accountsUsersPartialUpdate('0', {
                first_name: editForm.firstName,
                last_name: editForm.lastName
            });

            if (response.data) {
                setUserData(response.data);
                setIsEditing(false);
            }
        } catch (error) {
            console.error('Failed to update profile:', error);
        } finally {
            setIsSaving(false);
        }
    };

    const handleCancel = () => {
        setEditForm({
            firstName: userData?.first_name || '',
            lastName: userData?.last_name || ''
        });
        setIsEditing(false);
    };

    return (
        <>
            <Popover className={cn(
                "relative",
                placement === 'sidebar' && "w-full flex justify-center"
            )}>
                {({ open }) => (
                    <>
                        <PopoverButton
                            onClick={handleMenuOpen}
                            className="relative flex items-center justify-center w-9 h-9 rounded-full 
                       bg-dark-200 text-white hover:bg-dark-100 transition duration-200"
                        >
                            {isLoading ? (
                                <div className="w-5 h-5 border-2 border-white/30 border-t-white rounded-full animate-spin" />
                            ) : (
                                <User size={20} />
                            )}
                        </PopoverButton>

                        <Transition
                            show={open}
                            enter="transition duration-200 ease-out"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="transition duration-150 ease-in"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <PopoverPanel
                                className={cn(
                                    "fixed z-50 w-72",
                                    placement === 'navbar' && "right-4 mt-2",
                                    placement === 'sidebar' && "left-20 bottom-20"
                                )}
                                static
                            >
                                <div className="relative">
                                    {placement === 'sidebar' && (
                                        <div className="absolute -left-2 bottom-4 w-4 h-4 
                                  bg-dark-secondary rotate-45 border-l border-b border-dark-200" />
                                    )}

                                    <div className="relative bg-dark-secondary border border-dark-200 rounded-lg shadow-lg">
                                        <div className="p-4">
                                            {isEditing ? (
                                                <div className="flex flex-col space-y-3">
                                                    <input
                                                        type="text"
                                                        value={editForm.firstName}
                                                        onChange={(e) => setEditForm(prev => ({ ...prev, firstName: e.target.value }))}
                                                        placeholder="First Name"
                                                        className="w-full px-3 py-1.5 bg-dark-100 border border-dark-200 rounded-lg
                                   text-sm text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
                                                    />
                                                    <input
                                                        type="text"
                                                        value={editForm.lastName}
                                                        onChange={(e) => setEditForm(prev => ({ ...prev, lastName: e.target.value }))}
                                                        placeholder="Last Name"
                                                        className="w-full px-3 py-1.5 bg-dark-100 border border-dark-200 rounded-lg
                                   text-sm text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
                                                    />
                                                    <div className="flex justify-end space-x-2 pt-2">
                                                        <button
                                                            onClick={handleCancel}
                                                            disabled={isSaving}
                                                            className="p-1.5 text-white/70 hover:text-white hover:bg-dark-100 
                                     rounded-lg transition duration-200"
                                                        >
                                                            <X size={16} />
                                                        </button>
                                                        <button
                                                            onClick={handleSave}
                                                            disabled={isSaving}
                                                            className="p-1.5 text-white/70 hover:text-white hover:bg-dark-100 
                                     rounded-lg transition duration-200"
                                                        >
                                                            {isSaving ? (
                                                                <div className="w-4 h-4 border-2 border-white/30 border-t-white rounded-full animate-spin" />
                                                            ) : (
                                                                <Check size={16} />
                                                            )}
                                                        </button>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className="flex items-center justify-between">
                                                    <div className="flex-1">
                                                        <p className="text-sm font-medium text-white">
                                                            {userData?.first_name} {userData?.last_name}
                                                        </p>
                                                        <p className="text-sm text-white/70">
                                                            {userData?.email}
                                                        </p>
                                                    </div>
                                                    <button
                                                        onClick={() => setIsEditing(true)}
                                                        className="p-1.5 text-white/50 hover:text-white hover:bg-dark-100 
                                   rounded-lg transition duration-200"
                                                    >
                                                        <PencilLine size={16} />
                                                    </button>
                                                </div>
                                            )}
                                        </div>

                                        <div className="h-px bg-dark-200" />

                                        <div className="p-1">
                                            <button
                                                onClick={() => setShowLogoutDialog(true)}
                                                className="w-full flex items-center space-x-2 p-2 text-white/70 hover:text-white 
                               hover:bg-dark-100 rounded-lg transition duration-200"
                                            >
                                                <LogOut size={16} />
                                                <span className="text-sm">Log out</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </PopoverPanel>
                        </Transition>
                    </>
                )}
            </Popover>

            <LogoutDialog
                isOpen={showLogoutDialog}
                onClose={() => setShowLogoutDialog(false)}
                onConfirm={() => {
                    setShowLogoutDialog(false);
                    logout();
                }}
            />
        </>
    );
};

export default UserProfileMenu;