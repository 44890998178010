// src/components/chat/NavBar/Controls.tsx
import { Brain, Database } from "lucide-react";
import { Dropdown } from "components/ui/Dropdown";
import type { ControlsProps } from "./types";
import { cn } from "utils/classnames";



export const Controls = ({
    models,
    knowledgeBases,
    selectedModel,
    selectedKnowledgeBase,
    isUpdating,
    onModelChange,
    onKnowledgeBaseChange,
    className,
}: ControlsProps & { className?: string }) => {


    return (
        <div className={cn(
            // Base styles
            "flex",
            // Mobile: vertical layout with full width items
            "flex-col w-full space-y-4",
            // Desktop: horizontal layout with spacing
            "xl:flex-row xl:items-center xl:space-y-0 xl:space-x-4",
            className
        )}>
            {/* Model Selection Dropdown */}
            <div className="w-full xl:w-48">
                <Dropdown
                    icon={<Brain size={16} />}
                    value={selectedModel}
                    onChange={onModelChange}
                    options={models.map((model) => ({
                        value: model.id ?? "",
                        label: model.name ?? "",
                        description: model.provider ?? "",
                    }))}
                    disabled={isUpdating}
                />
            </div>

            {/* Knowledge Base Selection Dropdown */}
            <div className="w-full xl:w-48">
                <Dropdown
                    icon={<Database size={16} />}
                    value={selectedKnowledgeBase}
                    onChange={onKnowledgeBaseChange}
                    options={knowledgeBases.map((kb) => ({
                        value: kb.id ?? "",
                        label: kb.name ?? "",
                        description: kb.description ?? "",
                    }))}
                    disabled={isUpdating}
                />
            </div>

        </div>
    );
};