import {Configuration, AuthApi, ChatsApi, KnowledgeApi, ModelsApi, AccountsApi} from '../api/generated-client';
import {  BASE_PATH } from '../api/generated-client/base';

// Re-export BASE_PATH from generated client
export { BASE_PATH } from '../api/generated-client/base';

// Create configurations
const createBaseConfiguration = (token?: string) => {
    return new Configuration({
        basePath: BASE_PATH,
        accessToken: token
    });
};

// Auth API (doesn't require token)
export const authApi = new AuthApi(createBaseConfiguration());

// Create authorized APIs (requires token)
export const createAuthorizedApis = (token: string) => {
    const config = createBaseConfiguration(token);
    return {
        chatsApi: new ChatsApi(config),
        knowledgeApi: new KnowledgeApi(config),
        modelsApi: new ModelsApi(config),
        accountsApi: new AccountsApi(config),
    };
};

// Type for all authorized APIs
export type AuthorizedApis = ReturnType<typeof createAuthorizedApis>;

// Helper function to get the full URL for streaming endpoints
export const getStreamUrl = (path: string) => {
    const url = new URL(path, BASE_PATH);
    return url.toString();
};
