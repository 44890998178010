import React, { useState } from 'react';
import { BookOpenText, Home, Search, SquarePen } from 'lucide-react';
import { Link, useLocation } from 'react-router-dom';
import { cn } from 'utils/classnames';
import { LogoutDialog } from 'components/chat/NavBar/LogoutDialog';
import { useAuth } from 'context/AuthContext';
import UserProfileMenu from './UserProfileMenu';

const VerticalIconContainer = ({ children }: { children: React.ReactNode }) => {
    return (
        <div className="flex flex-col items-center gap-y-3 w-full">{children}</div>
    );
};

// Add this interface to types.d.ts or a separate types file
interface SidebarLink {
    icon: React.ElementType;
    href: string;
    active: boolean;
    label: string;
}

export const Sidebar = ({ children }: { children: React.ReactNode }) => {
    const location = useLocation();
    const { logout } = useAuth();
    const [showLogoutDialog, setShowLogoutDialog] = useState(false);

    const navLinks: SidebarLink[] = [
        {
            icon: Home,
            href: '/chat',
            active: location.pathname.includes('/chat'),
            label: 'Chat',
        },
        {
            icon: Search,
            href: '/search',
            active: location.pathname === '/search',
            label: 'Search',
        },
        {
            icon: BookOpenText,
            href: '/library',
            active: location.pathname === '/library',
            label: 'Library',
        },
    ];



    return (
        <div>
            <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-20 lg:flex-col">
                <div className="flex grow flex-col items-center justify-between gap-y-5 bg-dark-secondary px-2 py-8">
                    <a href="/chat">
                        <SquarePen className="cursor-pointer" />
                    </a>
                    <VerticalIconContainer>
                        {navLinks.map((link, i) => (
                            <Link
                                key={i}
                                to={link.href}
                                className={cn(
                                    'relative flex flex-row items-center justify-center cursor-pointer hover:bg-black/10 dark:hover:bg-white/10 duration-150 transition w-full py-2 rounded-lg',
                                    link.active
                                        ? 'text-black dark:text-white'
                                        : 'text-black/70 dark:text-white/70',
                                )}
                            >
                                <link.icon />
                                {link.active && (
                                    <div className="absolute right-0 -mr-2 h-full w-1 rounded-l-lg bg-black dark:bg-white" />
                                )}
                            </Link>
                        ))}
                    </VerticalIconContainer>

                    {/* Logout Button */}
                    <UserProfileMenu placement="sidebar" />

                </div>
            </div>
            <LogoutDialog
                isOpen={showLogoutDialog}
                onClose={() => setShowLogoutDialog(false)}
                onConfirm={() => {
                    setShowLogoutDialog(false);
                    logout();

                }}
            />

            <div className="fixed bottom-0 w-full z-50 flex flex-row items-center gap-x-6 bg-light-primary dark:bg-dark-primary px-4 py-4 shadow-sm lg:hidden">
                {navLinks.map((link, i) => (
                    <Link
                        to={link.href}
                        key={i}
                        className={cn(
                            'relative flex flex-col items-center space-y-1 text-center w-full',
                            link.active
                                ? 'text-black dark:text-white'
                                : 'text-black dark:text-white/70',
                        )}
                    >
                        {link.active && (
                            <div className="absolute top-0 -mt-4 h-1 w-full rounded-b-lg bg-black dark:bg-white" />
                        )}
                        <link.icon />
                        <p className="text-xs">{link.label}</p>
                    </Link>
                ))}
            </div>

            <main className="lg:pl-20 bg-dark-primary min-h-screen">
                <div className="max-w-screen-lg lg:mx-auto mx-4">
                    {children}
                </div>
            </main>
        </div>
    );
};