import React, { useEffect, useRef, useState } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import { ArrowUp, CopyPlus } from 'lucide-react';
import { cn } from 'utils/classnames';

interface MessageInputProps {
  sendMessage: (message: string) => void;
  loading: boolean;
}

const MessageInput = ({ sendMessage, loading }: MessageInputProps) => {
  const [message, setMessage] = useState('');
  const [textareaRows, setTextareaRows] = useState(1);
  const [mode, setMode] = useState<'multi' | 'single'>('single');
  const inputRef = useRef<HTMLTextAreaElement | null>(null);

  useEffect(() => {
    if (textareaRows >= 2 && message && mode === 'single') {
      setMode('multi');
    } else if (!message && mode === 'multi') {
      setMode('single');
    }
  }, [textareaRows, mode, message]);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      const activeElement = document.activeElement;
      const isInputFocused = activeElement?.tagName === 'INPUT' ||
        activeElement?.tagName === 'TEXTAREA' ||
        activeElement?.hasAttribute('contenteditable');

      if (e.key === '/' && !isInputFocused) {
        e.preventDefault();
        inputRef.current?.focus();
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, []);

  return (
    <form
      onSubmit={(e) => {
        if (loading) return;
        e.preventDefault();
        if (message.trim()) {
          sendMessage(message);
          setMessage('');
        }
      }}
      onKeyDown={(e) => {
        if (e.key === 'Enter' && !e.shiftKey && !loading) {
          e.preventDefault();
          if (message.trim()) {
            sendMessage(message);
            setMessage('');
          }
        }
      }}
      className={cn(
        'bg-dark-secondary p-4 flex items-center overflow-hidden border border-dark-200',
        mode === 'multi' ? 'flex-col rounded-lg' : 'flex-row rounded-full'
      )}
    >
      {mode === 'single' && (
        <button
          type="button"
          className="p-2 text-white/70 rounded-xl hover:bg-dark-100 
                   transition duration-200 hover:text-white"
        >
          <CopyPlus size={20} />
        </button>
      )}

      <TextareaAutosize
        ref={inputRef}
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        onHeightChange={(height, props) => {
          setTextareaRows(Math.ceil(height / props.rowHeight));
        }}
        className="bg-transparent dark:placeholder:text-white/50 placeholder:text-sm 
                 text-sm dark:text-white resize-none focus:outline-none w-full px-2 
                 max-h-24 lg:max-h-36 xl:max-h-48 flex-grow flex-shrink"
        placeholder="Ask a follow-up"
      />

      {mode === 'single' && (
        <button
          type="submit"
          disabled={!message.trim() || loading}
          className="bg-blue-500 text-white disabled:text-white/50 hover:bg-blue-600 
                   transition duration-200 disabled:bg-blue-500/50 rounded-full p-2"
        >
          <ArrowUp size={20} />
        </button>
      )}

      {mode === 'multi' && (
        <div className="flex flex-row items-center justify-between w-full pt-2">
          <button
            type="button"
            className="p-2 text-white/70 rounded-xl hover:bg-dark-100 
                     transition duration-200 hover:text-white"
          >
            <CopyPlus size={20} />
          </button>
          <button
            type="submit"
            disabled={!message.trim() || loading}
            className="bg-blue-500 text-white disabled:text-white/50 hover:bg-blue-600 
                     transition duration-200 disabled:bg-blue-500/50 rounded-full p-2"
          >
            <ArrowUp size={20} />
          </button>
        </div>
      )}
    </form>
  );
};

export default MessageInput;