// src/hooks/useAuthFlow.ts
import { useState } from 'react';
import { useAuth } from '../context/AuthContext';
import { authApi } from '../utils/api';

export type AuthStep = 'email' | 'otp';

interface UseAuthFlowProps {
  onSuccess?: () => void;
  onError?: (error: string) => void;
}

export const useAuthFlow = ({ onSuccess, onError }: UseAuthFlowProps = {}) => {
  const { login } = useAuth();
  const [email, setEmail] = useState('');
  const [currentStep, setCurrentStep] = useState<AuthStep>('email');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleEmailSubmit = async (email: string) => {
    setIsLoading(true);
    setError(null);
    try {
      await authApi.authOtpCreate({ email });
      setEmail(email);
      setCurrentStep('otp');
    } catch (err: any) {
      const errorMessage = err.response?.data?.detail || 'Failed to send OTP';
      setError(errorMessage);
      onError?.(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  const handleOtpSubmit = async (otp: string) => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await authApi.authTokenCreate({ email, otp });
      if (response.data.token) {
        login(response.data.token);
        onSuccess?.();
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (err: any) {
      const errorMessage = err.response?.data?.detail || 'Invalid OTP';
      setError(errorMessage);
      onError?.(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  const resetFlow = () => {
    setCurrentStep('email');
    setEmail('');
    setError(null);
  };

  return {
    currentStep,
    email,
    isLoading,
    error,
    handleEmailSubmit,
    handleOtpSubmit,
    resetFlow,
  };
};