import { Edit2 } from "lucide-react";
import { cn } from "utils/classnames";
import type { TitleProps } from "./types";
import { Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from '@headlessui/react';
import { Fragment } from 'react';

const truncateText = (text: string, limit: number = 24): string => {
    if (!text) return '';
    return text.length > limit ? text.slice(0, limit) + '...' : text;
};

export const Title = ({
    title,
    isEditing,
    newTitle,
    isUpdating,
    onTitleChange,
    onTitleSubmit,
    onEditStart,
    onEditCancel,
}: TitleProps) => {
    const handleKeyDown = (e: React.KeyboardEvent) => {
        if (e.key === "Enter") {
            onTitleSubmit();
        } else if (e.key === "Escape") {
            onEditCancel();
        }
    };

    const displayTitle = truncateText(title || "New Chat");
    const shouldShowTooltip = title && title.length > 24;

    return (
        <div className="flex items-center justify-center">
            {/* Edit Dialog for both mobile and desktop */}
            <Transition appear show={isEditing} as={Fragment}>
                <Dialog as="div" className="relative z-50" onClose={onEditCancel}>
                    <TransitionChild
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black/30" />
                    </TransitionChild>

                    <div className="fixed inset-0">
                        <div className="flex min-h-full items-center justify-center p-4">
                            <TransitionChild
                                as={Fragment}
                                enter="ease-out duration-200"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-100"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <DialogPanel className="w-full max-w-md transform rounded-2xl 
                                                      bg-dark-secondary p-6 text-left align-middle 
                                                      shadow-xl transition-all border border-dark-200">
                                    <DialogTitle className="text-lg font-medium text-white mb-4">
                                        Edit Title
                                    </DialogTitle>

                                    <div className="mt-2">
                                        <input
                                            type="text"
                                            value={newTitle}
                                            onChange={(e) => onTitleChange(e.target.value)}
                                            onKeyDown={handleKeyDown}
                                            className="w-full px-3 py-2 bg-dark-100 border border-dark-200 
                                                     rounded-lg text-white focus:outline-none 
                                                     focus:ring-2 focus:ring-blue-500"
                                            placeholder="Enter title"
                                            disabled={isUpdating}
                                            autoFocus
                                        />
                                    </div>

                                    <div className="mt-6 flex justify-end space-x-3">
                                        <button
                                            onClick={onEditCancel}
                                            className="px-3 py-2 text-sm text-white/70 hover:text-white
                                                     transition-colors rounded-lg hover:bg-dark-100"
                                            disabled={isUpdating}
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            onClick={onTitleSubmit}
                                            disabled={isUpdating}
                                            className="px-3 py-2 text-sm text-white bg-blue-500 
                                                     hover:bg-blue-600 rounded-lg transition-colors
                                                     disabled:opacity-50 flex items-center space-x-2"
                                        >
                                            {isUpdating ? (
                                                <>
                                                    <div className="w-4 h-4 border-2 border-white/30 border-t-white rounded-full animate-spin" />
                                                    <span>Saving...</span>
                                                </>
                                            ) : (
                                                <span>Save</span>
                                            )}
                                        </button>
                                    </div>
                                </DialogPanel>
                            </TransitionChild>
                        </div>
                    </div>
                </Dialog>
            </Transition>

            {/* Title Display */}
            <div className="group relative">
                <button
                    onClick={onEditStart}
                    className={cn(
                        "flex items-center space-x-2",
                        "text-white hover:text-blue-400 transition-colors",
                        "disabled:opacity-50"
                    )}
                    disabled={isUpdating}
                >
                    <h1 className="text-base lg:text-lg font-medium line-clamp-1">
                        {displayTitle}
                    </h1>
                    <Edit2 size={14} className="flex-shrink-0 lg:w-4 lg:h-4" />
                </button>

                {/* Tooltip - only show on desktop */}
                {shouldShowTooltip && (
                    <div className="hidden lg:block absolute left-1/2 -translate-x-1/2 top-full mt-2 
                                 px-3 py-1.5 bg-dark-100 rounded-lg text-sm text-white/70 
                                 opacity-0 group-hover:opacity-100 transition-opacity duration-200 
                                 whitespace-nowrap z-[60] shadow-lg border border-dark-200">
                        {title}
                        <div className="absolute left-1/2 -translate-x-1/2 -top-1 
                                      w-2 h-2 bg-dark-100 rotate-45 
                                      border-t border-l border-dark-200" />
                    </div>
                )}
            </div>
        </div>
    );
};