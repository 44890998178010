// src/components/ui/Dropdown.tsx
import { Fragment } from 'react';
import { Popover, PopoverButton, PopoverPanel, Transition } from '@headlessui/react';
import { ChevronDown } from 'lucide-react';
import { cn } from '../../utils/classnames';

export interface DropdownOption {
    value: string;
    label: string;
    icon?: JSX.Element;
    description?: string;
    disabled?: boolean;
}

interface DropdownProps {
    value: string;
    options: DropdownOption[];
    onChange: (value: string) => void;
    className?: string;
    buttonClassName?: string;
    label?: string;
    disabled?: boolean;
    icon?: JSX.Element;
}

export const Dropdown = ({
    value,
    options,
    onChange,
    className,
    buttonClassName,
    label,
    disabled = false,
    icon
}: DropdownProps) => {
    const selectedOption = options.find(opt => opt.value === value);

    return (
        <Popover className={cn("relative w-full", className)}>
            {({ open, close }) => (
                <>
                    <PopoverButton
                        disabled={disabled}
                        className={cn(
                            "flex items-center justify-between w-full px-3 py-2",
                            "bg-dark-secondary border border-dark-200 rounded-lg",
                            "text-white/70 hover:text-white transition-colors",
                            "disabled:opacity-50 disabled:cursor-not-allowed",
                            buttonClassName
                        )}
                    >
                        <div className="flex items-center space-x-2">
                            {icon && <span className="flex-shrink-0">{icon}</span>}
                            {selectedOption?.icon && (
                                <span className="flex-shrink-0">{selectedOption.icon}</span>
                            )}
                            <span className="text-sm font-medium truncate">
                                {selectedOption?.label || label || 'Select option'}
                            </span>
                        </div>
                        <ChevronDown
                            size={16}
                            className={cn(
                                "transition-transform duration-200",
                                open && "transform rotate-180"
                            )}
                        />
                    </PopoverButton>

                    <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0 translate-y-1"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-in duration-150"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 translate-y-1"
                    >
                        <PopoverPanel className="absolute z-50 w-full mt-2">
                            <div className="bg-dark-primary border border-dark-200 rounded-lg shadow-lg overflow-hidden">
                                {options.map((option) => (
                                    <button
                                        key={option.value}
                                        onClick={() => {
                                            onChange(option.value);
                                            close(); // Close the dropdown after selection
                                        }}
                                        disabled={option.disabled}
                                        className={cn(
                                            "w-full text-left px-4 py-3",
                                            "flex items-center space-x-3",
                                            "hover:bg-dark-secondary transition-colors",
                                            value === option.value ? "bg-dark-200 text-white" : "text-white/70",
                                            option.disabled && "opacity-50 cursor-not-allowed"
                                        )}
                                    >
                                        {option.icon && (
                                            <span className="flex-shrink-0">{option.icon}</span>
                                        )}
                                        <div className="flex-1 min-w-0">
                                            <div className="font-medium text-sm truncate">
                                                {option.label}
                                            </div>
                                            {option.description && (
                                                <div className="text-xs text-white/50 truncate">
                                                    {option.description}
                                                </div>
                                            )}
                                        </div>
                                    </button>
                                ))}
                            </div>
                        </PopoverPanel>
                    </Transition>
                </>
            )}
        </Popover>
    );
};