import React, { useState, useEffect, useRef, useCallback } from "react";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import { Message, RoleEnum } from "../api/generated-client";
import Chat from "../components/chat/Chat";
import EmptyChat from "../components/chat/EmptyChat";
import NavBar from "components/chat/NavBar/NavBar";
import axios from 'axios';

export const ChatPage = () => {
  const { threadId } = useParams();
  const [searchParams] = useSearchParams();
  const initialPrompt = searchParams.get('prompt');
  const navigate = useNavigate();
  const { apis } = useAuth();

  // State Management
  const [messages, setMessages] = useState<Message[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [messageAppeared, setMessageAppeared] = useState(false);
  const abortControllerRef = useRef<AbortController | null>(null);
  const initialPromptHandledRef = useRef(false);

  // Model and knowledge base state
  const [selectedModel, setSelectedModel] = useState<string>('');
  const [selectedKnowledgeBase, setSelectedKnowledgeBase] = useState<string>('');

  // Load messages when thread changes
  useEffect(() => {
    const loadMessages = async () => {
      if (!threadId || !apis?.chatsApi) return;

      try {
        setIsLoading(true);
        const messagesResponse = await apis.chatsApi.chatsThreadsMessagesList(threadId);
        setMessages(messagesResponse.data.results || []);
        initialPromptHandledRef.current = true; // Mark as handled since we're loading existing thread
      } catch (error) {
        console.error("Failed to load messages:", error);
        setError("Failed to load messages");
      } finally {
        setIsLoading(false);
      }
    };

    loadMessages();
  }, [threadId, apis?.chatsApi]);



  // Cleanup on unmount
  useEffect(() => {
    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, []);

  const handleMessageSend = useCallback(async (message: string) => {
    if (!message.trim() || isLoading) return;

    try {
      setIsLoading(true);
      setError(null);
      setMessageAppeared(false);
      let accumulatedResponse = '';
      let previousChunk = '';

      // Create new thread if none exists
      let currentThreadId = threadId;
      if (!currentThreadId) {
        const response = await apis?.chatsApi?.chatsThreadsCreate({
          title: message.length > 30 ? `${message.substring(0, 30)}...` : message,
          model: selectedModel,
          knowledge_base: selectedKnowledgeBase
        });

        if (response?.data.id) {
          currentThreadId = response.data.id;
          navigate(`/chat/${currentThreadId}`, { replace: true });
        } else {
          throw new Error('Failed to create new thread');
        }
      }

      abortControllerRef.current = new AbortController();

      // Send user message
      const messageResponse = await apis?.chatsApi?.chatsThreadsMessagesCreate(currentThreadId, {
        content: message,
        role: RoleEnum.User
      });

      if (messageResponse) {
        setMessages(prev => [...prev, messageResponse.data]);

        // Update document title with the first message
        if (messages.length === 0) {
          document.title = `${message.length > 30 ? message.substring(0, 30) + '...' : message} - Chat`;
        }
      }

      // Helper function to update messages during streaming
      const updateStreamingMessage = (content: string) => {
        setMessages(prev => {
          const newMessages = [...prev];
          const lastMessage = newMessages[newMessages.length - 1];

          if (lastMessage?.role === RoleEnum.Assistant) {
            lastMessage.content = content;
            return [...newMessages];
          } else {
            return [...newMessages, {
              content: content,
              role: RoleEnum.Assistant,
              created: new Date().toISOString()
            }];
          }
        });
      };

      // Stream the response
      await apis?.chatsApi.chatsThreadsMessagesStreamRetrieve(
        currentThreadId,
        {
          signal: abortControllerRef.current.signal,
          responseType: 'stream',
          onDownloadProgress: (progressEvent) => {
            const response = progressEvent.event.target as XMLHttpRequest;
            const currentChunk = response.responseText;
            const newContent = currentChunk.slice(previousChunk.length);
            previousChunk = currentChunk;

            const lines = newContent.split('\n');
            for (const line of lines) {
              if (line.startsWith('data: ')) {
                const data = line.slice(6);
                if (data === '[DONE]') {
                  setIsLoading(false);
                  continue;
                }

                try {
                  const parsedData = JSON.parse(data);
                  if (parsedData.error) {
                    setError(parsedData.error);
                    setIsLoading(false);
                    continue;
                  }

                  accumulatedResponse += parsedData.content || '';
                  updateStreamingMessage(accumulatedResponse);
                  setMessageAppeared(true);
                } catch (error) {
                  console.error('Error parsing stream data:', error);
                }
              }
            }
          }
        }
      );

      // After stream completes, fetch just the last message
      try {
        const messagesResponse = await apis?.chatsApi.chatsThreadsMessagesList(currentThreadId, 1, messages.length + 1);

        if (messagesResponse?.data.results?.[0]) {
          const lastMessage = messagesResponse.data.results[0];
          // Update the message with proper ID and relevant chunks
          setMessages(prev => {
            const newMessages = [...prev];
            const lastIndex = newMessages.length - 1;
            if (lastIndex >= 0 && newMessages[lastIndex].role === RoleEnum.Assistant) {
              newMessages[lastIndex] = {
                ...newMessages[lastIndex],
                id: lastMessage.id,
                relevant_chunks: lastMessage.relevant_chunks
              };
            }
            return newMessages;
          });
        }
      } catch (error) {
        console.error('Failed to fetch last message:', error);
      }

    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('Request was cancelled');
      } else {
        setError('Failed to send message');
        console.error('Error:', error);
      }
    } finally {
      setIsLoading(false);
    }
  }, [
    apis?.chatsApi,
    isLoading,
    messages.length,
    navigate,
    selectedKnowledgeBase,
    selectedModel,
    threadId
  ]); // Add all dependencies used in the function

  // Update the useEffect to include handleMessageSend in dependencies
  useEffect(() => {
    const handleInitialPrompt = async () => {
      if (!initialPrompt || !apis?.chatsApi || initialPromptHandledRef.current || isLoading) return;

      try {
        initialPromptHandledRef.current = true;
        await handleMessageSend(initialPrompt);
        // Remove the prompt from the URL without navigating
        const newUrl = window.location.pathname;
        window.history.replaceState({}, '', newUrl);
      } catch (error) {
        console.error("Failed to handle initial prompt:", error);
      }
    };

    handleInitialPrompt();
  }, [initialPrompt, apis?.chatsApi, isLoading, handleMessageSend]);


  // If no thread ID, show empty state
  if (!threadId && !initialPrompt) {
    return (
      <EmptyChat
        sendMessage={handleMessageSend}
        loading={isLoading}
        selectedModel={selectedModel}
        selectedKnowledgeBase={selectedKnowledgeBase}
        onModelChange={setSelectedModel}
        onKnowledgeBaseChange={setSelectedKnowledgeBase}
      />
    );
  }

  // Show chat with messages
  return (
    <div className="flex flex-col min-h-screen">
      <NavBar
        chatId={threadId!}
        messages={messages}
      />

      <div className="flex-1 overflow-y-auto custom-scrollbar">
        <Chat
          loading={isLoading}
          messages={messages}
          sendMessage={handleMessageSend}
          messageAppeared={messageAppeared}
          rewrite={() => { }} // Implement if needed
        />
      </div>

      {error && (
        <div className="fixed bottom-24 left-1/2 transform -translate-x-1/2 
                     bg-red-500 text-white px-4 py-2 rounded-lg">
          {error}
        </div>
      )}
    </div>
  );
};

export default ChatPage;