/* tslint:disable */
/* eslint-disable */
/**
 * AskPR knowledge base chat backend API
 * AskPR knowledge base chat backend
 *
 * The version of the OpenAPI document: 0.0.1 (v1)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface ChangePasswordRequest
 */
export interface ChangePasswordRequest {
    /**
     * 
     * @type {string}
     * @memberof ChangePasswordRequest
     */
    'old_password': string;
    /**
     * 
     * @type {string}
     * @memberof ChangePasswordRequest
     */
    'new_password': string;
}
/**
 * 
 * @export
 * @interface ErrorResponse
 */
export interface ErrorResponse {
    /**
     * 
     * @type {Array<Error>}
     * @memberof ErrorResponse
     */
    'errors': Array<Error>;
}
/**
 * 
 * @export
 * @interface Icebreaker
 */
export interface Icebreaker {
    /**
     * 
     * @type {string}
     * @memberof Icebreaker
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Icebreaker
     */
    'knowledge_base'?: string;
    /**
     * 
     * @type {string}
     * @memberof Icebreaker
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof Icebreaker
     */
    'description'?: string;
    /**
     * 
     * @type {number}
     * @memberof Icebreaker
     */
    'sequence'?: number;
    /**
     * 
     * @type {string}
     * @memberof Icebreaker
     */
    'prompt'?: string;
    /**
     * 
     * @type {string}
     * @memberof Icebreaker
     */
    'thumbnail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Icebreaker
     */
    'full_image'?: string | null;
}
/**
 * 
 * @export
 * @interface KnowledgeBase
 */
export interface KnowledgeBase {
    /**
     * 
     * @type {string}
     * @memberof KnowledgeBase
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnowledgeBase
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnowledgeBase
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnowledgeBase
     */
    'slug'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnowledgeBase
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnowledgeBase
     */
    'valid_from'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KnowledgeBase
     */
    'valid_to'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof KnowledgeBase
     */
    'is_default'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof KnowledgeBase
     */
    'is_active'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof KnowledgeBase
     */
    'document_count'?: number;
    /**
     * 
     * @type {Array<Icebreaker>}
     * @memberof KnowledgeBase
     */
    'icebreakers'?: Array<Icebreaker>;
    /**
     * 
     * @type {string}
     * @memberof KnowledgeBase
     */
    'created'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnowledgeBase
     */
    'modified'?: string;
}
/**
 * 
 * @export
 * @interface LLMModel
 */
export interface LLMModel {
    /**
     * 
     * @type {string}
     * @memberof LLMModel
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof LLMModel
     */
    'name'?: string;
    /**
     * 
     * @type {LLMModelProviderEnum}
     * @memberof LLMModel
     */
    'provider'?: LLMModelProviderEnum;
    /**
     * 
     * @type {UseTypeEnum}
     * @memberof LLMModel
     */
    'use_type'?: UseTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof LLMModel
     */
    'url'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LLMModel
     */
    'modified_at'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof LLMModel
     */
    'is_default'?: boolean;
    /**
     * 
     * @type {any}
     * @memberof LLMModel
     */
    'details'?: any | null;
    /**
     * 
     * @type {string}
     * @memberof LLMModel
     */
    'template'?: string | null;
}


/**
 * * `ollama` - Ollama * `openai` - OpenAI * `replicate` - Replicate
 * @export
 * @enum {string}
 */

export const LLMModelProviderEnum = {
    Ollama: 'ollama',
    Openai: 'openai',
    Replicate: 'replicate'
} as const;

export type LLMModelProviderEnum = typeof LLMModelProviderEnum[keyof typeof LLMModelProviderEnum];


/**
 * 
 * @export
 * @interface Message
 */
export interface Message {
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    'thread'?: string;
    /**
     * 
     * @type {RoleEnum}
     * @memberof Message
     */
    'role'?: RoleEnum;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    'content': string;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    'created'?: string;
    /**
     * 
     * @type {Array<MessageRelevantChunk>}
     * @memberof Message
     */
    'relevant_chunks'?: Array<MessageRelevantChunk>;
}


/**
 * 
 * @export
 * @interface MessageRelevantChunk
 */
export interface MessageRelevantChunk {
    /**
     * 
     * @type {string}
     * @memberof MessageRelevantChunk
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof MessageRelevantChunk
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof MessageRelevantChunk
     */
    'chunk_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof MessageRelevantChunk
     */
    'document_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof MessageRelevantChunk
     */
    'category_name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MessageRelevantChunk
     */
    'chunk_number'?: number;
    /**
     * 
     * @type {string}
     * @memberof MessageRelevantChunk
     */
    'content'?: string;
    /**
     * 
     * @type {any}
     * @memberof MessageRelevantChunk
     */
    'metadata'?: any;
    /**
     * 
     * @type {number}
     * @memberof MessageRelevantChunk
     */
    'similarity'?: number;
    /**
     * 
     * @type {string}
     * @memberof MessageRelevantChunk
     */
    'created'?: string;
    /**
     * 
     * @type {string}
     * @memberof MessageRelevantChunk
     */
    'modified'?: string;
}
/**
 * 
 * @export
 * @interface MessageRequest
 */
export interface MessageRequest {
    /**
     * 
     * @type {RoleEnum}
     * @memberof MessageRequest
     */
    'role'?: RoleEnum;
    /**
     * 
     * @type {string}
     * @memberof MessageRequest
     */
    'content': string;
}


/**
 * 
 * @export
 * @interface ModelError
 */
export interface ModelError {
    /**
     * 
     * @type {string}
     * @memberof ModelError
     */
    'message': string;
    /**
     * 
     * @type {string}
     * @memberof ModelError
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof ModelError
     */
    'field'?: string;
    /**
     * 
     * @type {any}
     * @memberof ModelError
     */
    'extra_data'?: any;
}
/**
 * 
 * @export
 * @interface OTPDevice
 */
export interface OTPDevice {
    /**
     * 
     * @type {number}
     * @memberof OTPDevice
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof OTPDevice
     */
    'name'?: string;
    /**
     * 
     * @type {OTPDeviceTypeEnum}
     * @memberof OTPDevice
     */
    'type': OTPDeviceTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof OTPDevice
     */
    'confirmed'?: boolean;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof OTPDevice
     */
    'extra_data'?: { [key: string]: string; };
}


/**
 * 
 * @export
 * @interface OTPDeviceConfirmRequest
 */
export interface OTPDeviceConfirmRequest {
    /**
     * 
     * @type {string}
     * @memberof OTPDeviceConfirmRequest
     */
    'otp': string;
}
/**
 * 
 * @export
 * @interface OTPDeviceRequest
 */
export interface OTPDeviceRequest {
    /**
     * 
     * @type {string}
     * @memberof OTPDeviceRequest
     */
    'name'?: string;
    /**
     * 
     * @type {OTPDeviceTypeEnum}
     * @memberof OTPDeviceRequest
     */
    'type': OTPDeviceTypeEnum;
}


/**
 * * `email` - email * `totp` - totp * `sms` - sms
 * @export
 * @enum {string}
 */

export const OTPDeviceTypeEnum = {
    Email: 'email',
    Totp: 'totp',
    Sms: 'sms'
} as const;

export type OTPDeviceTypeEnum = typeof OTPDeviceTypeEnum[keyof typeof OTPDeviceTypeEnum];


/**
 * 
 * @export
 * @interface OTPObtain
 */
export interface OTPObtain {
    /**
     * 
     * @type {string}
     * @memberof OTPObtain
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof OTPObtain
     */
    'otp'?: string;
    /**
     * 
     * @type {string}
     * @memberof OTPObtain
     */
    'password'?: string;
}
/**
 * 
 * @export
 * @interface OTPObtainRequest
 */
export interface OTPObtainRequest {
    /**
     * 
     * @type {string}
     * @memberof OTPObtainRequest
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof OTPObtainRequest
     */
    'otp'?: string;
    /**
     * 
     * @type {string}
     * @memberof OTPObtainRequest
     */
    'password'?: string;
    /**
     * 
     * @type {string}
     * @memberof OTPObtainRequest
     */
    'redirect_path'?: string;
}
/**
 * 
 * @export
 * @interface PaginatedKnowledgeBaseList
 */
export interface PaginatedKnowledgeBaseList {
    /**
     * 
     * @type {number}
     * @memberof PaginatedKnowledgeBaseList
     */
    'count': number;
    /**
     * 
     * @type {string}
     * @memberof PaginatedKnowledgeBaseList
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaginatedKnowledgeBaseList
     */
    'previous'?: string | null;
    /**
     * 
     * @type {Array<KnowledgeBase>}
     * @memberof PaginatedKnowledgeBaseList
     */
    'results': Array<KnowledgeBase>;
}
/**
 * 
 * @export
 * @interface PaginatedLLMModelList
 */
export interface PaginatedLLMModelList {
    /**
     * 
     * @type {number}
     * @memberof PaginatedLLMModelList
     */
    'count': number;
    /**
     * 
     * @type {string}
     * @memberof PaginatedLLMModelList
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaginatedLLMModelList
     */
    'previous'?: string | null;
    /**
     * 
     * @type {Array<LLMModel>}
     * @memberof PaginatedLLMModelList
     */
    'results': Array<LLMModel>;
}
/**
 * 
 * @export
 * @interface PaginatedMessageList
 */
export interface PaginatedMessageList {
    /**
     * 
     * @type {number}
     * @memberof PaginatedMessageList
     */
    'count': number;
    /**
     * 
     * @type {string}
     * @memberof PaginatedMessageList
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaginatedMessageList
     */
    'previous'?: string | null;
    /**
     * 
     * @type {Array<Message>}
     * @memberof PaginatedMessageList
     */
    'results': Array<Message>;
}
/**
 * 
 * @export
 * @interface PaginatedOTPDeviceList
 */
export interface PaginatedOTPDeviceList {
    /**
     * 
     * @type {number}
     * @memberof PaginatedOTPDeviceList
     */
    'count': number;
    /**
     * 
     * @type {string}
     * @memberof PaginatedOTPDeviceList
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaginatedOTPDeviceList
     */
    'previous'?: string | null;
    /**
     * 
     * @type {Array<OTPDevice>}
     * @memberof PaginatedOTPDeviceList
     */
    'results': Array<OTPDevice>;
}
/**
 * 
 * @export
 * @interface PaginatedThreadList
 */
export interface PaginatedThreadList {
    /**
     * 
     * @type {number}
     * @memberof PaginatedThreadList
     */
    'count': number;
    /**
     * 
     * @type {string}
     * @memberof PaginatedThreadList
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaginatedThreadList
     */
    'previous'?: string | null;
    /**
     * 
     * @type {Array<Thread>}
     * @memberof PaginatedThreadList
     */
    'results': Array<Thread>;
}
/**
 * 
 * @export
 * @interface PaginatedUserDeviceList
 */
export interface PaginatedUserDeviceList {
    /**
     * 
     * @type {number}
     * @memberof PaginatedUserDeviceList
     */
    'count': number;
    /**
     * 
     * @type {string}
     * @memberof PaginatedUserDeviceList
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaginatedUserDeviceList
     */
    'previous'?: string | null;
    /**
     * 
     * @type {Array<UserDevice>}
     * @memberof PaginatedUserDeviceList
     */
    'results': Array<UserDevice>;
}
/**
 * 
 * @export
 * @interface PatchedMessageRequest
 */
export interface PatchedMessageRequest {
    /**
     * 
     * @type {RoleEnum}
     * @memberof PatchedMessageRequest
     */
    'role'?: RoleEnum;
    /**
     * 
     * @type {string}
     * @memberof PatchedMessageRequest
     */
    'content'?: string;
}


/**
 * 
 * @export
 * @interface PatchedThreadRequest
 */
export interface PatchedThreadRequest {
    /**
     * 
     * @type {string}
     * @memberof PatchedThreadRequest
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedThreadRequest
     */
    'model'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedThreadRequest
     */
    'knowledge_base'?: string | null;
}
/**
 * 
 * @export
 * @interface PatchedUser2FARequest
 */
export interface PatchedUser2FARequest {
    /**
     * 
     * @type {boolean}
     * @memberof PatchedUser2FARequest
     */
    'is_required'?: boolean;
}
/**
 * 
 * @export
 * @interface PatchedUserDeviceRequest
 */
export interface PatchedUserDeviceRequest {
    /**
     * 
     * @type {string}
     * @memberof PatchedUserDeviceRequest
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedUserDeviceRequest
     */
    'registration_id'?: string;
    /**
     * Unique device identifier
     * @type {string}
     * @memberof PatchedUserDeviceRequest
     */
    'device_id'?: string | null;
    /**
     * Inactive devices will not be sent notifications
     * @type {boolean}
     * @memberof PatchedUserDeviceRequest
     */
    'active'?: boolean;
    /**
     * 
     * @type {UserDeviceTypeEnum}
     * @memberof PatchedUserDeviceRequest
     */
    'type'?: UserDeviceTypeEnum;
}


/**
 * 
 * @export
 * @interface PatchedUserIdentityRequest
 */
export interface PatchedUserIdentityRequest {
    /**
     * 
     * @type {string}
     * @memberof PatchedUserIdentityRequest
     */
    'first_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedUserIdentityRequest
     */
    'last_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedUserIdentityRequest
     */
    'password'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedUserIdentityRequest
     */
    'email'?: string;
}
/**
 * 
 * @export
 * @interface PatchedUserRequest
 */
export interface PatchedUserRequest {
    /**
     * 
     * @type {string}
     * @memberof PatchedUserRequest
     */
    'first_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedUserRequest
     */
    'last_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedUserRequest
     */
    'phone_number'?: string | null;
}
/**
 * 
 * @export
 * @interface PushAction
 */
export interface PushAction {
    /**
     * 
     * @type {string}
     * @memberof PushAction
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof PushAction
     */
    'button_text': string;
    /**
     * 
     * @type {boolean}
     * @memberof PushAction
     */
    'authentication_required'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PushAction
     */
    'destructive'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PushAction
     */
    'foreground'?: boolean;
}
/**
 * 
 * @export
 * @interface PushActionCategory
 */
export interface PushActionCategory {
    /**
     * 
     * @type {string}
     * @memberof PushActionCategory
     */
    'name': string;
    /**
     * 
     * @type {Array<PushAction>}
     * @memberof PushActionCategory
     */
    'actions': Array<PushAction>;
}
/**
 * * `system` - System * `user` - User * `assistant` - Assistant * `tool` - Tool
 * @export
 * @enum {string}
 */

export const RoleEnum = {
    System: 'system',
    User: 'user',
    Assistant: 'assistant',
    Tool: 'tool'
} as const;

export type RoleEnum = typeof RoleEnum[keyof typeof RoleEnum];


/**
 * * `google-oauth2` - google-oauth2 * `facebook` - facebook
 * @export
 * @enum {string}
 */

export const SocialTokenObtainProviderEnum = {
    GoogleOauth2: 'google-oauth2',
    Facebook: 'facebook'
} as const;

export type SocialTokenObtainProviderEnum = typeof SocialTokenObtainProviderEnum[keyof typeof SocialTokenObtainProviderEnum];


/**
 * 
 * @export
 * @interface SocialTokenObtainRequest
 */
export interface SocialTokenObtainRequest {
    /**
     * 
     * @type {string}
     * @memberof SocialTokenObtainRequest
     */
    'access_token': string;
    /**
     * 
     * @type {SocialTokenObtainProviderEnum}
     * @memberof SocialTokenObtainRequest
     */
    'provider': SocialTokenObtainProviderEnum;
    /**
     * 
     * @type {string}
     * @memberof SocialTokenObtainRequest
     */
    'first_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof SocialTokenObtainRequest
     */
    'last_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof SocialTokenObtainRequest
     */
    'otp'?: string;
    /**
     * 
     * @type {string}
     * @memberof SocialTokenObtainRequest
     */
    'password'?: string;
}


/**
 * 
 * @export
 * @interface Thread
 */
export interface Thread {
    /**
     * 
     * @type {string}
     * @memberof Thread
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Thread
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof Thread
     */
    'model'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Thread
     */
    'knowledge_base'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Thread
     */
    'created'?: string;
    /**
     * 
     * @type {string}
     * @memberof Thread
     */
    'modified'?: string;
}
/**
 * 
 * @export
 * @interface ThreadRequest
 */
export interface ThreadRequest {
    /**
     * 
     * @type {string}
     * @memberof ThreadRequest
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof ThreadRequest
     */
    'model'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ThreadRequest
     */
    'knowledge_base'?: string | null;
}
/**
 * 
 * @export
 * @interface Token
 */
export interface Token {
    /**
     * 
     * @type {string}
     * @memberof Token
     */
    'token'?: string;
}
/**
 * 
 * @export
 * @interface TokenBlacklistRequest
 */
export interface TokenBlacklistRequest {
    /**
     * 
     * @type {string}
     * @memberof TokenBlacklistRequest
     */
    'refresh': string;
}
/**
 * 
 * @export
 * @interface TokenObtainRequest
 */
export interface TokenObtainRequest {
    /**
     * 
     * @type {string}
     * @memberof TokenObtainRequest
     */
    'otp'?: string;
    /**
     * 
     * @type {string}
     * @memberof TokenObtainRequest
     */
    'password'?: string;
    /**
     * 
     * @type {string}
     * @memberof TokenObtainRequest
     */
    'email'?: string;
}
/**
 * 
 * @export
 * @interface TokenRefreshSlidingRequest
 */
export interface TokenRefreshSlidingRequest {
    /**
     * 
     * @type {string}
     * @memberof TokenRefreshSlidingRequest
     */
    'token': string;
}
/**
 * 
 * @export
 * @interface TokenVerifyRequest
 */
export interface TokenVerifyRequest {
    /**
     * 
     * @type {string}
     * @memberof TokenVerifyRequest
     */
    'token': string;
}
/**
 * * `embedding` - Embedding * `completion` - Completion * `chat` - Chat * `multimodal` - Multimodal
 * @export
 * @enum {string}
 */

export const UseTypeEnum = {
    Embedding: 'embedding',
    Completion: 'completion',
    Chat: 'chat',
    Multimodal: 'multimodal'
} as const;

export type UseTypeEnum = typeof UseTypeEnum[keyof typeof UseTypeEnum];


/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'avatar'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'first_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'last_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'phone_number'?: string | null;
}
/**
 * 
 * @export
 * @interface User2FA
 */
export interface User2FA {
    /**
     * 
     * @type {boolean}
     * @memberof User2FA
     */
    'is_required'?: boolean;
}
/**
 * 
 * @export
 * @interface UserDevice
 */
export interface UserDevice {
    /**
     * 
     * @type {number}
     * @memberof UserDevice
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof UserDevice
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDevice
     */
    'registration_id': string;
    /**
     * Unique device identifier
     * @type {string}
     * @memberof UserDevice
     */
    'device_id'?: string | null;
    /**
     * Inactive devices will not be sent notifications
     * @type {boolean}
     * @memberof UserDevice
     */
    'active'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserDevice
     */
    'date_created'?: string | null;
    /**
     * 
     * @type {UserDeviceTypeEnum}
     * @memberof UserDevice
     */
    'type': UserDeviceTypeEnum;
}


/**
 * 
 * @export
 * @interface UserDeviceRequest
 */
export interface UserDeviceRequest {
    /**
     * 
     * @type {string}
     * @memberof UserDeviceRequest
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDeviceRequest
     */
    'registration_id': string;
    /**
     * Unique device identifier
     * @type {string}
     * @memberof UserDeviceRequest
     */
    'device_id'?: string | null;
    /**
     * Inactive devices will not be sent notifications
     * @type {boolean}
     * @memberof UserDeviceRequest
     */
    'active'?: boolean;
    /**
     * 
     * @type {UserDeviceTypeEnum}
     * @memberof UserDeviceRequest
     */
    'type': UserDeviceTypeEnum;
}


/**
 * * `ios` - ios * `android` - android * `web` - web
 * @export
 * @enum {string}
 */

export const UserDeviceTypeEnum = {
    Ios: 'ios',
    Android: 'android',
    Web: 'web'
} as const;

export type UserDeviceTypeEnum = typeof UserDeviceTypeEnum[keyof typeof UserDeviceTypeEnum];


/**
 * 
 * @export
 * @interface UserIdentity
 */
export interface UserIdentity {
    /**
     * 
     * @type {string}
     * @memberof UserIdentity
     */
    'first_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserIdentity
     */
    'last_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserIdentity
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserIdentity
     */
    'id'?: string;
}
/**
 * 
 * @export
 * @interface UserIdentityRequest
 */
export interface UserIdentityRequest {
    /**
     * 
     * @type {string}
     * @memberof UserIdentityRequest
     */
    'first_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserIdentityRequest
     */
    'last_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserIdentityRequest
     */
    'password'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserIdentityRequest
     */
    'email'?: string;
}
/**
 * 
 * @export
 * @interface UserRequest
 */
export interface UserRequest {
    /**
     * 
     * @type {string}
     * @memberof UserRequest
     */
    'first_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserRequest
     */
    'last_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserRequest
     */
    'phone_number'?: string | null;
}

/**
 * AccountsApi - axios parameter creator
 * @export
 */
export const AccountsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id A unique integer value identifying this user.
         * @param {PatchedUserRequest} [patchedUserRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsUsersPartialUpdate: async (id: string, patchedUserRequest?: PatchedUserRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('accountsUsersPartialUpdate', 'id', id)
            const localVarPath = `/api/v1/accounts/users/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchedUserRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A unique integer value identifying this user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsUsersRetrieve: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('accountsUsersRetrieve', 'id', id)
            const localVarPath = `/api/v1/accounts/users/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A unique integer value identifying this user.
         * @param {UserRequest} [userRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsUsersUpdate: async (id: string, userRequest?: UserRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('accountsUsersUpdate', 'id', id)
            const localVarPath = `/api/v1/accounts/users/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccountsApi - functional programming interface
 * @export
 */
export const AccountsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AccountsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id A unique integer value identifying this user.
         * @param {PatchedUserRequest} [patchedUserRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountsUsersPartialUpdate(id: string, patchedUserRequest?: PatchedUserRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountsUsersPartialUpdate(id, patchedUserRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountsApi.accountsUsersPartialUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id A unique integer value identifying this user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountsUsersRetrieve(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountsUsersRetrieve(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountsApi.accountsUsersRetrieve']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id A unique integer value identifying this user.
         * @param {UserRequest} [userRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountsUsersUpdate(id: string, userRequest?: UserRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountsUsersUpdate(id, userRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountsApi.accountsUsersUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AccountsApi - factory interface
 * @export
 */
export const AccountsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AccountsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id A unique integer value identifying this user.
         * @param {PatchedUserRequest} [patchedUserRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsUsersPartialUpdate(id: string, patchedUserRequest?: PatchedUserRequest, options?: RawAxiosRequestConfig): AxiosPromise<User> {
            return localVarFp.accountsUsersPartialUpdate(id, patchedUserRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id A unique integer value identifying this user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsUsersRetrieve(id: string, options?: RawAxiosRequestConfig): AxiosPromise<User> {
            return localVarFp.accountsUsersRetrieve(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id A unique integer value identifying this user.
         * @param {UserRequest} [userRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsUsersUpdate(id: string, userRequest?: UserRequest, options?: RawAxiosRequestConfig): AxiosPromise<User> {
            return localVarFp.accountsUsersUpdate(id, userRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AccountsApi - object-oriented interface
 * @export
 * @class AccountsApi
 * @extends {BaseAPI}
 */
export class AccountsApi extends BaseAPI {
    /**
     * 
     * @param {string} id A unique integer value identifying this user.
     * @param {PatchedUserRequest} [patchedUserRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public accountsUsersPartialUpdate(id: string, patchedUserRequest?: PatchedUserRequest, options?: RawAxiosRequestConfig) {
        return AccountsApiFp(this.configuration).accountsUsersPartialUpdate(id, patchedUserRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id A unique integer value identifying this user.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public accountsUsersRetrieve(id: string, options?: RawAxiosRequestConfig) {
        return AccountsApiFp(this.configuration).accountsUsersRetrieve(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id A unique integer value identifying this user.
     * @param {UserRequest} [userRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public accountsUsersUpdate(id: string, userRequest?: UserRequest, options?: RawAxiosRequestConfig) {
        return AccountsApiFp(this.configuration).accountsUsersUpdate(id, userRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {OTPObtainRequest} [oTPObtainRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authOtpCreate: async (oTPObtainRequest?: OTPObtainRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/auth/otp/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(oTPObtainRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} type OTP Device type
         * @param {OTPDeviceConfirmRequest} oTPDeviceConfirmRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authOtpDevicesConfirmCreate: async (id: string, type: string, oTPDeviceConfirmRequest: OTPDeviceConfirmRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('authOtpDevicesConfirmCreate', 'id', id)
            // verify required parameter 'type' is not null or undefined
            assertParamExists('authOtpDevicesConfirmCreate', 'type', type)
            // verify required parameter 'oTPDeviceConfirmRequest' is not null or undefined
            assertParamExists('authOtpDevicesConfirmCreate', 'oTPDeviceConfirmRequest', oTPDeviceConfirmRequest)
            const localVarPath = `/api/v1/auth/otp-devices/{id}/confirm/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(oTPDeviceConfirmRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {OTPDeviceRequest} oTPDeviceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authOtpDevicesCreate: async (oTPDeviceRequest: OTPDeviceRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'oTPDeviceRequest' is not null or undefined
            assertParamExists('authOtpDevicesCreate', 'oTPDeviceRequest', oTPDeviceRequest)
            const localVarPath = `/api/v1/auth/otp-devices/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(oTPDeviceRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} type OTP Device type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authOtpDevicesDestroy: async (id: string, type: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('authOtpDevicesDestroy', 'id', id)
            // verify required parameter 'type' is not null or undefined
            assertParamExists('authOtpDevicesDestroy', 'type', type)
            const localVarPath = `/api/v1/auth/otp-devices/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authOtpDevicesList: async (limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/auth/otp-devices/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} type OTP Device type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authOtpDevicesRetrieve: async (id: string, type: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('authOtpDevicesRetrieve', 'id', id)
            // verify required parameter 'type' is not null or undefined
            assertParamExists('authOtpDevicesRetrieve', 'type', type)
            const localVarPath = `/api/v1/auth/otp-devices/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SocialTokenObtainRequest} socialTokenObtainRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authSocialConnectCreate: async (socialTokenObtainRequest: SocialTokenObtainRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'socialTokenObtainRequest' is not null or undefined
            assertParamExists('authSocialConnectCreate', 'socialTokenObtainRequest', socialTokenObtainRequest)
            const localVarPath = `/api/v1/auth/social/connect/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(socialTokenObtainRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SocialTokenObtainRequest} socialTokenObtainRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authSocialCreate: async (socialTokenObtainRequest: SocialTokenObtainRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'socialTokenObtainRequest' is not null or undefined
            assertParamExists('authSocialCreate', 'socialTokenObtainRequest', socialTokenObtainRequest)
            const localVarPath = `/api/v1/auth/social/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(socialTokenObtainRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TokenBlacklistRequest} tokenBlacklistRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authTokenBlacklistCreate: async (tokenBlacklistRequest: TokenBlacklistRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tokenBlacklistRequest' is not null or undefined
            assertParamExists('authTokenBlacklistCreate', 'tokenBlacklistRequest', tokenBlacklistRequest)
            const localVarPath = `/api/v1/auth/token/blacklist/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tokenBlacklistRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TokenObtainRequest} [tokenObtainRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authTokenCreate: async (tokenObtainRequest?: TokenObtainRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/auth/token/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tokenObtainRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TokenRefreshSlidingRequest} tokenRefreshSlidingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authTokenRefreshCreate: async (tokenRefreshSlidingRequest: TokenRefreshSlidingRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tokenRefreshSlidingRequest' is not null or undefined
            assertParamExists('authTokenRefreshCreate', 'tokenRefreshSlidingRequest', tokenRefreshSlidingRequest)
            const localVarPath = `/api/v1/auth/token/refresh/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tokenRefreshSlidingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TokenVerifyRequest} tokenVerifyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authTokenVerifyCreate: async (tokenVerifyRequest: TokenVerifyRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tokenVerifyRequest' is not null or undefined
            assertParamExists('authTokenVerifyCreate', 'tokenVerifyRequest', tokenVerifyRequest)
            const localVarPath = `/api/v1/auth/token/verify/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tokenVerifyRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UserIdentityRequest} [userIdentityRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authUsersCreate: async (userIdentityRequest?: UserIdentityRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/auth/users/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userIdentityRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {PatchedUserIdentityRequest} [patchedUserIdentityRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authUsersPartialUpdate: async (id: string, patchedUserIdentityRequest?: PatchedUserIdentityRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('authUsersPartialUpdate', 'id', id)
            const localVarPath = `/api/v1/auth/users/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchedUserIdentityRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authUsersRetrieve: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('authUsersRetrieve', 'id', id)
            const localVarPath = `/api/v1/auth/users/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {ChangePasswordRequest} changePasswordRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authUsersSetPasswordCreate: async (id: string, changePasswordRequest: ChangePasswordRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('authUsersSetPasswordCreate', 'id', id)
            // verify required parameter 'changePasswordRequest' is not null or undefined
            assertParamExists('authUsersSetPasswordCreate', 'changePasswordRequest', changePasswordRequest)
            const localVarPath = `/api/v1/auth/users/{id}/set-password/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(changePasswordRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {PatchedUser2FARequest} [patchedUser2FARequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authUsersTwoFaPartialUpdate: async (id: string, patchedUser2FARequest?: PatchedUser2FARequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('authUsersTwoFaPartialUpdate', 'id', id)
            const localVarPath = `/api/v1/auth/users/{id}/two-fa/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchedUser2FARequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authUsersTwoFaRetrieve: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('authUsersTwoFaRetrieve', 'id', id)
            const localVarPath = `/api/v1/auth/users/{id}/two-fa/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {OTPObtainRequest} [oTPObtainRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authOtpCreate(oTPObtainRequest?: OTPObtainRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OTPObtain>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authOtpCreate(oTPObtainRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.authOtpCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} type OTP Device type
         * @param {OTPDeviceConfirmRequest} oTPDeviceConfirmRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authOtpDevicesConfirmCreate(id: string, type: string, oTPDeviceConfirmRequest: OTPDeviceConfirmRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authOtpDevicesConfirmCreate(id, type, oTPDeviceConfirmRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.authOtpDevicesConfirmCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {OTPDeviceRequest} oTPDeviceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authOtpDevicesCreate(oTPDeviceRequest: OTPDeviceRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OTPDevice>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authOtpDevicesCreate(oTPDeviceRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.authOtpDevicesCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} type OTP Device type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authOtpDevicesDestroy(id: string, type: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authOtpDevicesDestroy(id, type, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.authOtpDevicesDestroy']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authOtpDevicesList(limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedOTPDeviceList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authOtpDevicesList(limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.authOtpDevicesList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} type OTP Device type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authOtpDevicesRetrieve(id: string, type: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OTPDevice>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authOtpDevicesRetrieve(id, type, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.authOtpDevicesRetrieve']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {SocialTokenObtainRequest} socialTokenObtainRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authSocialConnectCreate(socialTokenObtainRequest: SocialTokenObtainRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Token>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authSocialConnectCreate(socialTokenObtainRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.authSocialConnectCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {SocialTokenObtainRequest} socialTokenObtainRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authSocialCreate(socialTokenObtainRequest: SocialTokenObtainRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Token>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authSocialCreate(socialTokenObtainRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.authSocialCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {TokenBlacklistRequest} tokenBlacklistRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authTokenBlacklistCreate(tokenBlacklistRequest: TokenBlacklistRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Token>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authTokenBlacklistCreate(tokenBlacklistRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.authTokenBlacklistCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {TokenObtainRequest} [tokenObtainRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authTokenCreate(tokenObtainRequest?: TokenObtainRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Token>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authTokenCreate(tokenObtainRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.authTokenCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {TokenRefreshSlidingRequest} tokenRefreshSlidingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authTokenRefreshCreate(tokenRefreshSlidingRequest: TokenRefreshSlidingRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Token>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authTokenRefreshCreate(tokenRefreshSlidingRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.authTokenRefreshCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {TokenVerifyRequest} tokenVerifyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authTokenVerifyCreate(tokenVerifyRequest: TokenVerifyRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Token>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authTokenVerifyCreate(tokenVerifyRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.authTokenVerifyCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UserIdentityRequest} [userIdentityRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authUsersCreate(userIdentityRequest?: UserIdentityRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserIdentity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authUsersCreate(userIdentityRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.authUsersCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {PatchedUserIdentityRequest} [patchedUserIdentityRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authUsersPartialUpdate(id: string, patchedUserIdentityRequest?: PatchedUserIdentityRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserIdentity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authUsersPartialUpdate(id, patchedUserIdentityRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.authUsersPartialUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authUsersRetrieve(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserIdentity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authUsersRetrieve(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.authUsersRetrieve']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {ChangePasswordRequest} changePasswordRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authUsersSetPasswordCreate(id: string, changePasswordRequest: ChangePasswordRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authUsersSetPasswordCreate(id, changePasswordRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.authUsersSetPasswordCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {PatchedUser2FARequest} [patchedUser2FARequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authUsersTwoFaPartialUpdate(id: string, patchedUser2FARequest?: PatchedUser2FARequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User2FA>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authUsersTwoFaPartialUpdate(id, patchedUser2FARequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.authUsersTwoFaPartialUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authUsersTwoFaRetrieve(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User2FA>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authUsersTwoFaRetrieve(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.authUsersTwoFaRetrieve']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthApiFp(configuration)
    return {
        /**
         * 
         * @param {OTPObtainRequest} [oTPObtainRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authOtpCreate(oTPObtainRequest?: OTPObtainRequest, options?: RawAxiosRequestConfig): AxiosPromise<OTPObtain> {
            return localVarFp.authOtpCreate(oTPObtainRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} type OTP Device type
         * @param {OTPDeviceConfirmRequest} oTPDeviceConfirmRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authOtpDevicesConfirmCreate(id: string, type: string, oTPDeviceConfirmRequest: OTPDeviceConfirmRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.authOtpDevicesConfirmCreate(id, type, oTPDeviceConfirmRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {OTPDeviceRequest} oTPDeviceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authOtpDevicesCreate(oTPDeviceRequest: OTPDeviceRequest, options?: RawAxiosRequestConfig): AxiosPromise<OTPDevice> {
            return localVarFp.authOtpDevicesCreate(oTPDeviceRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} type OTP Device type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authOtpDevicesDestroy(id: string, type: string, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.authOtpDevicesDestroy(id, type, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authOtpDevicesList(limit?: number, offset?: number, options?: RawAxiosRequestConfig): AxiosPromise<PaginatedOTPDeviceList> {
            return localVarFp.authOtpDevicesList(limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} type OTP Device type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authOtpDevicesRetrieve(id: string, type: string, options?: RawAxiosRequestConfig): AxiosPromise<OTPDevice> {
            return localVarFp.authOtpDevicesRetrieve(id, type, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SocialTokenObtainRequest} socialTokenObtainRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authSocialConnectCreate(socialTokenObtainRequest: SocialTokenObtainRequest, options?: RawAxiosRequestConfig): AxiosPromise<Token> {
            return localVarFp.authSocialConnectCreate(socialTokenObtainRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SocialTokenObtainRequest} socialTokenObtainRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authSocialCreate(socialTokenObtainRequest: SocialTokenObtainRequest, options?: RawAxiosRequestConfig): AxiosPromise<Token> {
            return localVarFp.authSocialCreate(socialTokenObtainRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TokenBlacklistRequest} tokenBlacklistRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authTokenBlacklistCreate(tokenBlacklistRequest: TokenBlacklistRequest, options?: RawAxiosRequestConfig): AxiosPromise<Token> {
            return localVarFp.authTokenBlacklistCreate(tokenBlacklistRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TokenObtainRequest} [tokenObtainRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authTokenCreate(tokenObtainRequest?: TokenObtainRequest, options?: RawAxiosRequestConfig): AxiosPromise<Token> {
            return localVarFp.authTokenCreate(tokenObtainRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TokenRefreshSlidingRequest} tokenRefreshSlidingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authTokenRefreshCreate(tokenRefreshSlidingRequest: TokenRefreshSlidingRequest, options?: RawAxiosRequestConfig): AxiosPromise<Token> {
            return localVarFp.authTokenRefreshCreate(tokenRefreshSlidingRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TokenVerifyRequest} tokenVerifyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authTokenVerifyCreate(tokenVerifyRequest: TokenVerifyRequest, options?: RawAxiosRequestConfig): AxiosPromise<Token> {
            return localVarFp.authTokenVerifyCreate(tokenVerifyRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UserIdentityRequest} [userIdentityRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authUsersCreate(userIdentityRequest?: UserIdentityRequest, options?: RawAxiosRequestConfig): AxiosPromise<UserIdentity> {
            return localVarFp.authUsersCreate(userIdentityRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {PatchedUserIdentityRequest} [patchedUserIdentityRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authUsersPartialUpdate(id: string, patchedUserIdentityRequest?: PatchedUserIdentityRequest, options?: RawAxiosRequestConfig): AxiosPromise<UserIdentity> {
            return localVarFp.authUsersPartialUpdate(id, patchedUserIdentityRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authUsersRetrieve(id: string, options?: RawAxiosRequestConfig): AxiosPromise<UserIdentity> {
            return localVarFp.authUsersRetrieve(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {ChangePasswordRequest} changePasswordRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authUsersSetPasswordCreate(id: string, changePasswordRequest: ChangePasswordRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.authUsersSetPasswordCreate(id, changePasswordRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {PatchedUser2FARequest} [patchedUser2FARequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authUsersTwoFaPartialUpdate(id: string, patchedUser2FARequest?: PatchedUser2FARequest, options?: RawAxiosRequestConfig): AxiosPromise<User2FA> {
            return localVarFp.authUsersTwoFaPartialUpdate(id, patchedUser2FARequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authUsersTwoFaRetrieve(id: string, options?: RawAxiosRequestConfig): AxiosPromise<User2FA> {
            return localVarFp.authUsersTwoFaRetrieve(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
    /**
     * 
     * @param {OTPObtainRequest} [oTPObtainRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authOtpCreate(oTPObtainRequest?: OTPObtainRequest, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).authOtpCreate(oTPObtainRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} type OTP Device type
     * @param {OTPDeviceConfirmRequest} oTPDeviceConfirmRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authOtpDevicesConfirmCreate(id: string, type: string, oTPDeviceConfirmRequest: OTPDeviceConfirmRequest, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).authOtpDevicesConfirmCreate(id, type, oTPDeviceConfirmRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {OTPDeviceRequest} oTPDeviceRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authOtpDevicesCreate(oTPDeviceRequest: OTPDeviceRequest, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).authOtpDevicesCreate(oTPDeviceRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} type OTP Device type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authOtpDevicesDestroy(id: string, type: string, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).authOtpDevicesDestroy(id, type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authOtpDevicesList(limit?: number, offset?: number, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).authOtpDevicesList(limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} type OTP Device type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authOtpDevicesRetrieve(id: string, type: string, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).authOtpDevicesRetrieve(id, type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SocialTokenObtainRequest} socialTokenObtainRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authSocialConnectCreate(socialTokenObtainRequest: SocialTokenObtainRequest, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).authSocialConnectCreate(socialTokenObtainRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SocialTokenObtainRequest} socialTokenObtainRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authSocialCreate(socialTokenObtainRequest: SocialTokenObtainRequest, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).authSocialCreate(socialTokenObtainRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TokenBlacklistRequest} tokenBlacklistRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authTokenBlacklistCreate(tokenBlacklistRequest: TokenBlacklistRequest, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).authTokenBlacklistCreate(tokenBlacklistRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TokenObtainRequest} [tokenObtainRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authTokenCreate(tokenObtainRequest?: TokenObtainRequest, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).authTokenCreate(tokenObtainRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TokenRefreshSlidingRequest} tokenRefreshSlidingRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authTokenRefreshCreate(tokenRefreshSlidingRequest: TokenRefreshSlidingRequest, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).authTokenRefreshCreate(tokenRefreshSlidingRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TokenVerifyRequest} tokenVerifyRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authTokenVerifyCreate(tokenVerifyRequest: TokenVerifyRequest, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).authTokenVerifyCreate(tokenVerifyRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserIdentityRequest} [userIdentityRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authUsersCreate(userIdentityRequest?: UserIdentityRequest, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).authUsersCreate(userIdentityRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {PatchedUserIdentityRequest} [patchedUserIdentityRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authUsersPartialUpdate(id: string, patchedUserIdentityRequest?: PatchedUserIdentityRequest, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).authUsersPartialUpdate(id, patchedUserIdentityRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authUsersRetrieve(id: string, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).authUsersRetrieve(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {ChangePasswordRequest} changePasswordRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authUsersSetPasswordCreate(id: string, changePasswordRequest: ChangePasswordRequest, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).authUsersSetPasswordCreate(id, changePasswordRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {PatchedUser2FARequest} [patchedUser2FARequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authUsersTwoFaPartialUpdate(id: string, patchedUser2FARequest?: PatchedUser2FARequest, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).authUsersTwoFaPartialUpdate(id, patchedUser2FARequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authUsersTwoFaRetrieve(id: string, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).authUsersTwoFaRetrieve(id, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ChatsApi - axios parameter creator
 * @export
 */
export const ChatsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ThreadRequest} [threadRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatsThreadsCreate: async (threadRequest?: ThreadRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/chats/threads/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(threadRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatsThreadsDestroy: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('chatsThreadsDestroy', 'id', id)
            const localVarPath = `/api/v1/chats/threads/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatsThreadsList: async (limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/chats/threads/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} threadPk 
         * @param {MessageRequest} messageRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatsThreadsMessagesCreate: async (threadPk: string, messageRequest: MessageRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'threadPk' is not null or undefined
            assertParamExists('chatsThreadsMessagesCreate', 'threadPk', threadPk)
            // verify required parameter 'messageRequest' is not null or undefined
            assertParamExists('chatsThreadsMessagesCreate', 'messageRequest', messageRequest)
            const localVarPath = `/api/v1/chats/threads/{thread_pk}/messages/`
                .replace(`{${"thread_pk"}}`, encodeURIComponent(String(threadPk)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(messageRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} threadPk 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatsThreadsMessagesDestroy: async (id: string, threadPk: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('chatsThreadsMessagesDestroy', 'id', id)
            // verify required parameter 'threadPk' is not null or undefined
            assertParamExists('chatsThreadsMessagesDestroy', 'threadPk', threadPk)
            const localVarPath = `/api/v1/chats/threads/{thread_pk}/messages/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"thread_pk"}}`, encodeURIComponent(String(threadPk)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} threadPk 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatsThreadsMessagesList: async (threadPk: string, limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'threadPk' is not null or undefined
            assertParamExists('chatsThreadsMessagesList', 'threadPk', threadPk)
            const localVarPath = `/api/v1/chats/threads/{thread_pk}/messages/`
                .replace(`{${"thread_pk"}}`, encodeURIComponent(String(threadPk)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} threadPk 
         * @param {PatchedMessageRequest} [patchedMessageRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatsThreadsMessagesPartialUpdate: async (id: string, threadPk: string, patchedMessageRequest?: PatchedMessageRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('chatsThreadsMessagesPartialUpdate', 'id', id)
            // verify required parameter 'threadPk' is not null or undefined
            assertParamExists('chatsThreadsMessagesPartialUpdate', 'threadPk', threadPk)
            const localVarPath = `/api/v1/chats/threads/{thread_pk}/messages/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"thread_pk"}}`, encodeURIComponent(String(threadPk)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchedMessageRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} threadPk 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatsThreadsMessagesRetrieve: async (id: string, threadPk: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('chatsThreadsMessagesRetrieve', 'id', id)
            // verify required parameter 'threadPk' is not null or undefined
            assertParamExists('chatsThreadsMessagesRetrieve', 'threadPk', threadPk)
            const localVarPath = `/api/v1/chats/threads/{thread_pk}/messages/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"thread_pk"}}`, encodeURIComponent(String(threadPk)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Streams data as it becomes available.
         * @param {string} threadPk 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatsThreadsMessagesStreamRetrieve: async (threadPk: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'threadPk' is not null or undefined
            assertParamExists('chatsThreadsMessagesStreamRetrieve', 'threadPk', threadPk)
            const localVarPath = `/api/v1/chats/threads/{thread_pk}/messages/stream/`
                .replace(`{${"thread_pk"}}`, encodeURIComponent(String(threadPk)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} threadPk 
         * @param {MessageRequest} messageRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatsThreadsMessagesUpdate: async (id: string, threadPk: string, messageRequest: MessageRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('chatsThreadsMessagesUpdate', 'id', id)
            // verify required parameter 'threadPk' is not null or undefined
            assertParamExists('chatsThreadsMessagesUpdate', 'threadPk', threadPk)
            // verify required parameter 'messageRequest' is not null or undefined
            assertParamExists('chatsThreadsMessagesUpdate', 'messageRequest', messageRequest)
            const localVarPath = `/api/v1/chats/threads/{thread_pk}/messages/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"thread_pk"}}`, encodeURIComponent(String(threadPk)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(messageRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {PatchedThreadRequest} [patchedThreadRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatsThreadsPartialUpdate: async (id: string, patchedThreadRequest?: PatchedThreadRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('chatsThreadsPartialUpdate', 'id', id)
            const localVarPath = `/api/v1/chats/threads/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchedThreadRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatsThreadsRetrieve: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('chatsThreadsRetrieve', 'id', id)
            const localVarPath = `/api/v1/chats/threads/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {ThreadRequest} [threadRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatsThreadsUpdate: async (id: string, threadRequest?: ThreadRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('chatsThreadsUpdate', 'id', id)
            const localVarPath = `/api/v1/chats/threads/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(threadRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ChatsApi - functional programming interface
 * @export
 */
export const ChatsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ChatsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {ThreadRequest} [threadRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async chatsThreadsCreate(threadRequest?: ThreadRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Thread>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.chatsThreadsCreate(threadRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ChatsApi.chatsThreadsCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async chatsThreadsDestroy(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.chatsThreadsDestroy(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ChatsApi.chatsThreadsDestroy']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async chatsThreadsList(limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedThreadList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.chatsThreadsList(limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ChatsApi.chatsThreadsList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} threadPk 
         * @param {MessageRequest} messageRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async chatsThreadsMessagesCreate(threadPk: string, messageRequest: MessageRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Message>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.chatsThreadsMessagesCreate(threadPk, messageRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ChatsApi.chatsThreadsMessagesCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} threadPk 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async chatsThreadsMessagesDestroy(id: string, threadPk: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.chatsThreadsMessagesDestroy(id, threadPk, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ChatsApi.chatsThreadsMessagesDestroy']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} threadPk 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async chatsThreadsMessagesList(threadPk: string, limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedMessageList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.chatsThreadsMessagesList(threadPk, limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ChatsApi.chatsThreadsMessagesList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} threadPk 
         * @param {PatchedMessageRequest} [patchedMessageRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async chatsThreadsMessagesPartialUpdate(id: string, threadPk: string, patchedMessageRequest?: PatchedMessageRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Message>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.chatsThreadsMessagesPartialUpdate(id, threadPk, patchedMessageRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ChatsApi.chatsThreadsMessagesPartialUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} threadPk 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async chatsThreadsMessagesRetrieve(id: string, threadPk: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Message>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.chatsThreadsMessagesRetrieve(id, threadPk, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ChatsApi.chatsThreadsMessagesRetrieve']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Streams data as it becomes available.
         * @param {string} threadPk 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async chatsThreadsMessagesStreamRetrieve(threadPk: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.chatsThreadsMessagesStreamRetrieve(threadPk, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ChatsApi.chatsThreadsMessagesStreamRetrieve']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} threadPk 
         * @param {MessageRequest} messageRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async chatsThreadsMessagesUpdate(id: string, threadPk: string, messageRequest: MessageRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Message>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.chatsThreadsMessagesUpdate(id, threadPk, messageRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ChatsApi.chatsThreadsMessagesUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {PatchedThreadRequest} [patchedThreadRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async chatsThreadsPartialUpdate(id: string, patchedThreadRequest?: PatchedThreadRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Thread>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.chatsThreadsPartialUpdate(id, patchedThreadRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ChatsApi.chatsThreadsPartialUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async chatsThreadsRetrieve(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Thread>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.chatsThreadsRetrieve(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ChatsApi.chatsThreadsRetrieve']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {ThreadRequest} [threadRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async chatsThreadsUpdate(id: string, threadRequest?: ThreadRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Thread>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.chatsThreadsUpdate(id, threadRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ChatsApi.chatsThreadsUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ChatsApi - factory interface
 * @export
 */
export const ChatsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ChatsApiFp(configuration)
    return {
        /**
         * 
         * @param {ThreadRequest} [threadRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatsThreadsCreate(threadRequest?: ThreadRequest, options?: RawAxiosRequestConfig): AxiosPromise<Thread> {
            return localVarFp.chatsThreadsCreate(threadRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatsThreadsDestroy(id: string, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.chatsThreadsDestroy(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatsThreadsList(limit?: number, offset?: number, options?: RawAxiosRequestConfig): AxiosPromise<PaginatedThreadList> {
            return localVarFp.chatsThreadsList(limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} threadPk 
         * @param {MessageRequest} messageRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatsThreadsMessagesCreate(threadPk: string, messageRequest: MessageRequest, options?: RawAxiosRequestConfig): AxiosPromise<Message> {
            return localVarFp.chatsThreadsMessagesCreate(threadPk, messageRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} threadPk 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatsThreadsMessagesDestroy(id: string, threadPk: string, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.chatsThreadsMessagesDestroy(id, threadPk, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} threadPk 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatsThreadsMessagesList(threadPk: string, limit?: number, offset?: number, options?: RawAxiosRequestConfig): AxiosPromise<PaginatedMessageList> {
            return localVarFp.chatsThreadsMessagesList(threadPk, limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} threadPk 
         * @param {PatchedMessageRequest} [patchedMessageRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatsThreadsMessagesPartialUpdate(id: string, threadPk: string, patchedMessageRequest?: PatchedMessageRequest, options?: RawAxiosRequestConfig): AxiosPromise<Message> {
            return localVarFp.chatsThreadsMessagesPartialUpdate(id, threadPk, patchedMessageRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} threadPk 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatsThreadsMessagesRetrieve(id: string, threadPk: string, options?: RawAxiosRequestConfig): AxiosPromise<Message> {
            return localVarFp.chatsThreadsMessagesRetrieve(id, threadPk, options).then((request) => request(axios, basePath));
        },
        /**
         * Streams data as it becomes available.
         * @param {string} threadPk 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatsThreadsMessagesStreamRetrieve(threadPk: string, options?: RawAxiosRequestConfig): AxiosPromise<File> {
            return localVarFp.chatsThreadsMessagesStreamRetrieve(threadPk, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} threadPk 
         * @param {MessageRequest} messageRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatsThreadsMessagesUpdate(id: string, threadPk: string, messageRequest: MessageRequest, options?: RawAxiosRequestConfig): AxiosPromise<Message> {
            return localVarFp.chatsThreadsMessagesUpdate(id, threadPk, messageRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {PatchedThreadRequest} [patchedThreadRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatsThreadsPartialUpdate(id: string, patchedThreadRequest?: PatchedThreadRequest, options?: RawAxiosRequestConfig): AxiosPromise<Thread> {
            return localVarFp.chatsThreadsPartialUpdate(id, patchedThreadRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatsThreadsRetrieve(id: string, options?: RawAxiosRequestConfig): AxiosPromise<Thread> {
            return localVarFp.chatsThreadsRetrieve(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {ThreadRequest} [threadRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatsThreadsUpdate(id: string, threadRequest?: ThreadRequest, options?: RawAxiosRequestConfig): AxiosPromise<Thread> {
            return localVarFp.chatsThreadsUpdate(id, threadRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ChatsApi - object-oriented interface
 * @export
 * @class ChatsApi
 * @extends {BaseAPI}
 */
export class ChatsApi extends BaseAPI {
    /**
     * 
     * @param {ThreadRequest} [threadRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatsApi
     */
    public chatsThreadsCreate(threadRequest?: ThreadRequest, options?: RawAxiosRequestConfig) {
        return ChatsApiFp(this.configuration).chatsThreadsCreate(threadRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatsApi
     */
    public chatsThreadsDestroy(id: string, options?: RawAxiosRequestConfig) {
        return ChatsApiFp(this.configuration).chatsThreadsDestroy(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatsApi
     */
    public chatsThreadsList(limit?: number, offset?: number, options?: RawAxiosRequestConfig) {
        return ChatsApiFp(this.configuration).chatsThreadsList(limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} threadPk 
     * @param {MessageRequest} messageRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatsApi
     */
    public chatsThreadsMessagesCreate(threadPk: string, messageRequest: MessageRequest, options?: RawAxiosRequestConfig) {
        return ChatsApiFp(this.configuration).chatsThreadsMessagesCreate(threadPk, messageRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} threadPk 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatsApi
     */
    public chatsThreadsMessagesDestroy(id: string, threadPk: string, options?: RawAxiosRequestConfig) {
        return ChatsApiFp(this.configuration).chatsThreadsMessagesDestroy(id, threadPk, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} threadPk 
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatsApi
     */
    public chatsThreadsMessagesList(threadPk: string, limit?: number, offset?: number, options?: RawAxiosRequestConfig) {
        return ChatsApiFp(this.configuration).chatsThreadsMessagesList(threadPk, limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} threadPk 
     * @param {PatchedMessageRequest} [patchedMessageRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatsApi
     */
    public chatsThreadsMessagesPartialUpdate(id: string, threadPk: string, patchedMessageRequest?: PatchedMessageRequest, options?: RawAxiosRequestConfig) {
        return ChatsApiFp(this.configuration).chatsThreadsMessagesPartialUpdate(id, threadPk, patchedMessageRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} threadPk 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatsApi
     */
    public chatsThreadsMessagesRetrieve(id: string, threadPk: string, options?: RawAxiosRequestConfig) {
        return ChatsApiFp(this.configuration).chatsThreadsMessagesRetrieve(id, threadPk, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Streams data as it becomes available.
     * @param {string} threadPk 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatsApi
     */
    public chatsThreadsMessagesStreamRetrieve(threadPk: string, options?: RawAxiosRequestConfig) {
        return ChatsApiFp(this.configuration).chatsThreadsMessagesStreamRetrieve(threadPk, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} threadPk 
     * @param {MessageRequest} messageRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatsApi
     */
    public chatsThreadsMessagesUpdate(id: string, threadPk: string, messageRequest: MessageRequest, options?: RawAxiosRequestConfig) {
        return ChatsApiFp(this.configuration).chatsThreadsMessagesUpdate(id, threadPk, messageRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {PatchedThreadRequest} [patchedThreadRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatsApi
     */
    public chatsThreadsPartialUpdate(id: string, patchedThreadRequest?: PatchedThreadRequest, options?: RawAxiosRequestConfig) {
        return ChatsApiFp(this.configuration).chatsThreadsPartialUpdate(id, patchedThreadRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatsApi
     */
    public chatsThreadsRetrieve(id: string, options?: RawAxiosRequestConfig) {
        return ChatsApiFp(this.configuration).chatsThreadsRetrieve(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {ThreadRequest} [threadRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatsApi
     */
    public chatsThreadsUpdate(id: string, threadRequest?: ThreadRequest, options?: RawAxiosRequestConfig) {
        return ChatsApiFp(this.configuration).chatsThreadsUpdate(id, threadRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * KnowledgeApi - axios parameter creator
 * @export
 */
export const KnowledgeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        knowledgeKnowledgeBasesList: async (limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/knowledge/knowledge-bases/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A unique integer value identifying this Knowledge Base.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        knowledgeKnowledgeBasesRetrieve: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('knowledgeKnowledgeBasesRetrieve', 'id', id)
            const localVarPath = `/api/v1/knowledge/knowledge-bases/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * KnowledgeApi - functional programming interface
 * @export
 */
export const KnowledgeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = KnowledgeApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async knowledgeKnowledgeBasesList(limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedKnowledgeBaseList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.knowledgeKnowledgeBasesList(limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['KnowledgeApi.knowledgeKnowledgeBasesList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id A unique integer value identifying this Knowledge Base.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async knowledgeKnowledgeBasesRetrieve(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KnowledgeBase>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.knowledgeKnowledgeBasesRetrieve(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['KnowledgeApi.knowledgeKnowledgeBasesRetrieve']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * KnowledgeApi - factory interface
 * @export
 */
export const KnowledgeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = KnowledgeApiFp(configuration)
    return {
        /**
         * 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        knowledgeKnowledgeBasesList(limit?: number, offset?: number, options?: RawAxiosRequestConfig): AxiosPromise<PaginatedKnowledgeBaseList> {
            return localVarFp.knowledgeKnowledgeBasesList(limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id A unique integer value identifying this Knowledge Base.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        knowledgeKnowledgeBasesRetrieve(id: string, options?: RawAxiosRequestConfig): AxiosPromise<KnowledgeBase> {
            return localVarFp.knowledgeKnowledgeBasesRetrieve(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * KnowledgeApi - object-oriented interface
 * @export
 * @class KnowledgeApi
 * @extends {BaseAPI}
 */
export class KnowledgeApi extends BaseAPI {
    /**
     * 
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KnowledgeApi
     */
    public knowledgeKnowledgeBasesList(limit?: number, offset?: number, options?: RawAxiosRequestConfig) {
        return KnowledgeApiFp(this.configuration).knowledgeKnowledgeBasesList(limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id A unique integer value identifying this Knowledge Base.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KnowledgeApi
     */
    public knowledgeKnowledgeBasesRetrieve(id: string, options?: RawAxiosRequestConfig) {
        return KnowledgeApiFp(this.configuration).knowledgeKnowledgeBasesRetrieve(id, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ModelsApi - axios parameter creator
 * @export
 */
export const ModelsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modelsModelsList: async (limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/models/models/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A unique integer value identifying this LLM Model.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modelsModelsRetrieve: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('modelsModelsRetrieve', 'id', id)
            const localVarPath = `/api/v1/models/models/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ModelsApi - functional programming interface
 * @export
 */
export const ModelsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ModelsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async modelsModelsList(limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedLLMModelList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.modelsModelsList(limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ModelsApi.modelsModelsList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id A unique integer value identifying this LLM Model.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async modelsModelsRetrieve(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LLMModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.modelsModelsRetrieve(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ModelsApi.modelsModelsRetrieve']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ModelsApi - factory interface
 * @export
 */
export const ModelsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ModelsApiFp(configuration)
    return {
        /**
         * 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modelsModelsList(limit?: number, offset?: number, options?: RawAxiosRequestConfig): AxiosPromise<PaginatedLLMModelList> {
            return localVarFp.modelsModelsList(limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id A unique integer value identifying this LLM Model.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modelsModelsRetrieve(id: string, options?: RawAxiosRequestConfig): AxiosPromise<LLMModel> {
            return localVarFp.modelsModelsRetrieve(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ModelsApi - object-oriented interface
 * @export
 * @class ModelsApi
 * @extends {BaseAPI}
 */
export class ModelsApi extends BaseAPI {
    /**
     * 
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelsApi
     */
    public modelsModelsList(limit?: number, offset?: number, options?: RawAxiosRequestConfig) {
        return ModelsApiFp(this.configuration).modelsModelsList(limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id A unique integer value identifying this LLM Model.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelsApi
     */
    public modelsModelsRetrieve(id: string, options?: RawAxiosRequestConfig) {
        return ModelsApiFp(this.configuration).modelsModelsRetrieve(id, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * NotificationsApi - axios parameter creator
 * @export
 */
export const NotificationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationsActionCategoriesList: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/notifications/action-categories/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UserDeviceRequest} userDeviceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationsDevicesCreate: async (userDeviceRequest: UserDeviceRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userDeviceRequest' is not null or undefined
            assertParamExists('notificationsDevicesCreate', 'userDeviceRequest', userDeviceRequest)
            const localVarPath = `/api/v1/notifications/devices/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userDeviceRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} registrationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationsDevicesDestroy: async (registrationId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'registrationId' is not null or undefined
            assertParamExists('notificationsDevicesDestroy', 'registrationId', registrationId)
            const localVarPath = `/api/v1/notifications/devices/{registration_id}/`
                .replace(`{${"registration_id"}}`, encodeURIComponent(String(registrationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationsDevicesList: async (limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/notifications/devices/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} registrationId 
         * @param {PatchedUserDeviceRequest} [patchedUserDeviceRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationsDevicesPartialUpdate: async (registrationId: string, patchedUserDeviceRequest?: PatchedUserDeviceRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'registrationId' is not null or undefined
            assertParamExists('notificationsDevicesPartialUpdate', 'registrationId', registrationId)
            const localVarPath = `/api/v1/notifications/devices/{registration_id}/`
                .replace(`{${"registration_id"}}`, encodeURIComponent(String(registrationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchedUserDeviceRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} registrationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationsDevicesRetrieve: async (registrationId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'registrationId' is not null or undefined
            assertParamExists('notificationsDevicesRetrieve', 'registrationId', registrationId)
            const localVarPath = `/api/v1/notifications/devices/{registration_id}/`
                .replace(`{${"registration_id"}}`, encodeURIComponent(String(registrationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} registrationId 
         * @param {UserDeviceRequest} userDeviceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationsDevicesUpdate: async (registrationId: string, userDeviceRequest: UserDeviceRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'registrationId' is not null or undefined
            assertParamExists('notificationsDevicesUpdate', 'registrationId', registrationId)
            // verify required parameter 'userDeviceRequest' is not null or undefined
            assertParamExists('notificationsDevicesUpdate', 'userDeviceRequest', userDeviceRequest)
            const localVarPath = `/api/v1/notifications/devices/{registration_id}/`
                .replace(`{${"registration_id"}}`, encodeURIComponent(String(registrationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userDeviceRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NotificationsApi - functional programming interface
 * @export
 */
export const NotificationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NotificationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async notificationsActionCategoriesList(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PushActionCategory>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.notificationsActionCategoriesList(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NotificationsApi.notificationsActionCategoriesList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UserDeviceRequest} userDeviceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async notificationsDevicesCreate(userDeviceRequest: UserDeviceRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDevice>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.notificationsDevicesCreate(userDeviceRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NotificationsApi.notificationsDevicesCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} registrationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async notificationsDevicesDestroy(registrationId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.notificationsDevicesDestroy(registrationId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NotificationsApi.notificationsDevicesDestroy']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async notificationsDevicesList(limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedUserDeviceList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.notificationsDevicesList(limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NotificationsApi.notificationsDevicesList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} registrationId 
         * @param {PatchedUserDeviceRequest} [patchedUserDeviceRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async notificationsDevicesPartialUpdate(registrationId: string, patchedUserDeviceRequest?: PatchedUserDeviceRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDevice>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.notificationsDevicesPartialUpdate(registrationId, patchedUserDeviceRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NotificationsApi.notificationsDevicesPartialUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} registrationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async notificationsDevicesRetrieve(registrationId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDevice>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.notificationsDevicesRetrieve(registrationId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NotificationsApi.notificationsDevicesRetrieve']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} registrationId 
         * @param {UserDeviceRequest} userDeviceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async notificationsDevicesUpdate(registrationId: string, userDeviceRequest: UserDeviceRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDevice>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.notificationsDevicesUpdate(registrationId, userDeviceRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NotificationsApi.notificationsDevicesUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * NotificationsApi - factory interface
 * @export
 */
export const NotificationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NotificationsApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationsActionCategoriesList(options?: RawAxiosRequestConfig): AxiosPromise<Array<PushActionCategory>> {
            return localVarFp.notificationsActionCategoriesList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UserDeviceRequest} userDeviceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationsDevicesCreate(userDeviceRequest: UserDeviceRequest, options?: RawAxiosRequestConfig): AxiosPromise<UserDevice> {
            return localVarFp.notificationsDevicesCreate(userDeviceRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} registrationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationsDevicesDestroy(registrationId: string, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.notificationsDevicesDestroy(registrationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationsDevicesList(limit?: number, offset?: number, options?: RawAxiosRequestConfig): AxiosPromise<PaginatedUserDeviceList> {
            return localVarFp.notificationsDevicesList(limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} registrationId 
         * @param {PatchedUserDeviceRequest} [patchedUserDeviceRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationsDevicesPartialUpdate(registrationId: string, patchedUserDeviceRequest?: PatchedUserDeviceRequest, options?: RawAxiosRequestConfig): AxiosPromise<UserDevice> {
            return localVarFp.notificationsDevicesPartialUpdate(registrationId, patchedUserDeviceRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} registrationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationsDevicesRetrieve(registrationId: string, options?: RawAxiosRequestConfig): AxiosPromise<UserDevice> {
            return localVarFp.notificationsDevicesRetrieve(registrationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} registrationId 
         * @param {UserDeviceRequest} userDeviceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationsDevicesUpdate(registrationId: string, userDeviceRequest: UserDeviceRequest, options?: RawAxiosRequestConfig): AxiosPromise<UserDevice> {
            return localVarFp.notificationsDevicesUpdate(registrationId, userDeviceRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NotificationsApi - object-oriented interface
 * @export
 * @class NotificationsApi
 * @extends {BaseAPI}
 */
export class NotificationsApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public notificationsActionCategoriesList(options?: RawAxiosRequestConfig) {
        return NotificationsApiFp(this.configuration).notificationsActionCategoriesList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserDeviceRequest} userDeviceRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public notificationsDevicesCreate(userDeviceRequest: UserDeviceRequest, options?: RawAxiosRequestConfig) {
        return NotificationsApiFp(this.configuration).notificationsDevicesCreate(userDeviceRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} registrationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public notificationsDevicesDestroy(registrationId: string, options?: RawAxiosRequestConfig) {
        return NotificationsApiFp(this.configuration).notificationsDevicesDestroy(registrationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public notificationsDevicesList(limit?: number, offset?: number, options?: RawAxiosRequestConfig) {
        return NotificationsApiFp(this.configuration).notificationsDevicesList(limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} registrationId 
     * @param {PatchedUserDeviceRequest} [patchedUserDeviceRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public notificationsDevicesPartialUpdate(registrationId: string, patchedUserDeviceRequest?: PatchedUserDeviceRequest, options?: RawAxiosRequestConfig) {
        return NotificationsApiFp(this.configuration).notificationsDevicesPartialUpdate(registrationId, patchedUserDeviceRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} registrationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public notificationsDevicesRetrieve(registrationId: string, options?: RawAxiosRequestConfig) {
        return NotificationsApiFp(this.configuration).notificationsDevicesRetrieve(registrationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} registrationId 
     * @param {UserDeviceRequest} userDeviceRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public notificationsDevicesUpdate(registrationId: string, userDeviceRequest: UserDeviceRequest, options?: RawAxiosRequestConfig) {
        return NotificationsApiFp(this.configuration).notificationsDevicesUpdate(registrationId, userDeviceRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



