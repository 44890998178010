// src/pages/MagicLinkPage.tsx
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { authApi } from '../utils/api';
import { decodeMagicLink } from '../utils/magiclink';

const MagicLinkPage: React.FC = () => {
    const { token } = useParams();
    const navigate = useNavigate();
    const { login } = useAuth();
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const verifyMagicLink = async () => {

            if (!token) {
                setError('Magic link is invalid or has expired');
                return;
            }

            try {
                // Decode the magic link
                const { email, otp } = decodeMagicLink(token);

                // Login with the decoded credentials
                const response = await authApi.authTokenCreate({ email, otp });

                if (response.data.token) {
                    login(response.data.token);
                    navigate('/chat', { replace: true });
                } else {
                    throw new Error('Invalid response from server');
                }
            } catch (err) {
                console.error('Magic link verification failed:', err);
                setError('This magic link is invalid or has expired. Please request a new one.');
            }
        };

        verifyMagicLink();
    }, [token, login, navigate]);

    return (
        <div className="min-h-screen w-full flex items-center justify-center bg-dark-primary">
            <div className="w-full max-w-md mx-4">
                <div className="bg-dark-secondary/80 backdrop-blur-md p-8 rounded-2xl shadow-2xl border border-dark-200 text-center">
                    {error ? (
                        <div className="space-y-4">
                            <div className="bg-red-500/10 border border-red-500/50 text-red-200 px-6 py-4 rounded-lg">
                                {error}
                            </div>
                            <button
                                onClick={() => navigate('/login')}
                                className="text-white/70 hover:text-white transition-colors"
                            >
                                Return to login
                            </button>
                        </div>
                    ) : (
                        <div className="space-y-6">
                            <div className="relative w-16 h-16 mx-auto">
                                {/* Outer ring animation */}
                                <div className="absolute inset-0 border-4 border-blue-500/30 rounded-full animate-ping" />

                                {/* Inner ring animation */}
                                <div className="absolute inset-0 border-4 border-t-blue-500 border-r-transparent border-b-transparent border-l-transparent rounded-full animate-spin" />

                                {/* Center dot */}
                                <div className="absolute inset-2 bg-blue-500 rounded-full" />
                            </div>

                            <div className="space-y-2">
                                <h2 className="text-xl font-semibold text-white">
                                    Logging you in...
                                </h2>
                                <p className="text-white/70 text-sm">
                                    Please wait while we verify your magic link
                                </p>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default MagicLinkPage;