import { Fragment } from 'react';
import { Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from '@headlessui/react';

interface ConfirmationDialogProps {
    isOpen: boolean;
    title: string;
    message: string;
    confirmLabel?: string;
    cancelLabel?: string;
    isConfirming?: boolean;
    confirmingLabel?: string;
    onClose: () => void;
    onConfirm: () => void;
    isDanger?: boolean;
}

const ConfirmationDialog = ({
    isOpen,
    title,
    message,
    confirmLabel = 'Confirm',
    cancelLabel = 'Cancel',
    isConfirming = false,
    confirmingLabel = 'Processing...',
    onClose,
    onConfirm,
    isDanger = false
}: ConfirmationDialogProps) => {
    return (
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog
                as="div"
                className="relative z-50"
                onClose={() => !isConfirming && onClose()}
            >
                <div className="fixed inset-0 bg-black/30" aria-hidden="true" />

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4">
                        <TransitionChild
                            as={Fragment}
                            enter="ease-out duration-200"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-100"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <DialogPanel className="w-full max-w-md transform rounded-2xl 
                                  bg-dark-secondary border border-dark-200 
                                  p-6 text-left align-middle shadow-xl transition-all">
                                <DialogTitle className="text-lg font-medium text-white mb-4">
                                    {title}
                                </DialogTitle>
                                <p className="text-sm text-white/70 mb-6">
                                    {message}
                                </p>
                                <div className="flex justify-end space-x-4">
                                    <button
                                        onClick={onClose}
                                        disabled={isConfirming}
                                        className="px-3 py-2 text-sm text-white/70 hover:text-white 
                           transition-colors rounded-lg hover:bg-dark-100 
                           disabled:opacity-50 disabled:cursor-not-allowed"
                                    >
                                        {cancelLabel}
                                    </button>
                                    <button
                                        onClick={onConfirm}
                                        disabled={isConfirming}
                                        className={`px-3 py-2 text-sm text-white 
                             rounded-lg transition-colors
                             disabled:opacity-50 disabled:cursor-not-allowed
                             flex items-center space-x-2
                             ${isDanger
                                                ? 'bg-red-500 hover:bg-red-600'
                                                : 'bg-blue-500 hover:bg-blue-600'
                                            }`}
                                    >
                                        {isConfirming ? (
                                            <>
                                                <div className="w-4 h-4 border-2 border-white border-t-transparent 
                                    rounded-full animate-spin" />
                                                <span>{confirmingLabel}</span>
                                            </>
                                        ) : (
                                            <span>{confirmLabel}</span>
                                        )}
                                    </button>
                                </div>
                            </DialogPanel>
                        </TransitionChild>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
};

export default ConfirmationDialog;