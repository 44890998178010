import React, { useEffect, useRef } from 'react';
import { ArrowRight } from 'lucide-react';
import TextareaAutosize from 'react-textarea-autosize';
import { Dropdown } from '../ui/Dropdown';
import { LLMModel, KnowledgeBase } from 'api/generated-client';
import { cn } from '../../utils/classnames';
import { Brain, Database } from 'lucide-react';

interface EmptyChatMessageInputProps {
    sendMessage: (message: string) => void;
    loading: boolean;
    models: LLMModel[];
    knowledgeBases: KnowledgeBase[];
    selectedModel: string;
    selectedKnowledgeBase: string;
    onModelChange: (modelId: string) => void;
    onKnowledgeBaseChange: (kbId: string) => void;
    value: string;
    onChange: (value: string) => void;
}

const EmptyChatMessageInput = ({
    sendMessage,
    loading,
    models,
    knowledgeBases,
    selectedModel,
    selectedKnowledgeBase,
    onModelChange,
    onKnowledgeBaseChange,
    value,
    onChange
}: EmptyChatMessageInputProps) => {
    const inputRef = useRef<HTMLTextAreaElement | null>(null);

    useEffect(() => {
        const handleKeyDown = (e: KeyboardEvent) => {
            const activeElement = document.activeElement;
            const isInputFocused =
                activeElement?.tagName === 'INPUT' ||
                activeElement?.tagName === 'TEXTAREA' ||
                activeElement?.hasAttribute('contenteditable');

            if (e.key === '/' && !isInputFocused) {
                e.preventDefault();
                inputRef.current?.focus();
            }
        };

        document.addEventListener('keydown', handleKeyDown);
        return () => document.removeEventListener('keydown', handleKeyDown);
    }, []);

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        if (!loading && value.trim()) {
            sendMessage(value);
            onChange(''); // Clear input after sending
        }
    };

    const handleKeyDown = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter' && !e.shiftKey && !loading) {
            e.preventDefault();
            if (value.trim()) {
                sendMessage(value);
                onChange(''); // Clear input after sending
            }
        }
    };

    const Controls = () => (
        <div className="flex w-full flex-col space-y-4 lg:flex-row lg:items-center lg:space-y-0 lg:space-x-4">
            <div className="w-full lg:w-48">
                <Dropdown
                    value={selectedModel}
                    onChange={onModelChange}
                    icon={<Brain size={16} />}
                    options={models.map(model => ({
                        value: model.id || '',
                        label: model.name || '',
                        description: model.provider || undefined
                    }))}
                    disabled={loading}
                />
            </div>
            <div className="w-full lg:w-48">
                <Dropdown
                    value={selectedKnowledgeBase}
                    onChange={onKnowledgeBaseChange}
                    icon={<Database size={16} />}
                    options={knowledgeBases.map(kb => ({
                        value: kb.id || '',
                        label: kb.name || '',
                        description: kb.description || undefined
                    }))}
                    disabled={loading}
                />
            </div>
        </div>
    );

    const SendButton = () => (
        <button
            onClick={handleSubmit}
            disabled={!value.trim() || loading}
            className={cn(
                "bg-blue-500 text-white disabled:text-white/50",
                "hover:bg-blue-600 transition duration-200",
                "disabled:bg-blue-500/50 rounded-full p-2",
                "flex-shrink-0"
            )}
        >
            <ArrowRight size={20} />
        </button>
    );

    return (
        <div className="w-full space-y-4">
            {/* Mobile Controls - shown above input */}
            <div className="lg:hidden">
                <Controls />
            </div>

            <div className="flex flex-col bg-dark-secondary px-5 pt-5 pb-5 rounded-lg w-full border border-dark-200">
                <div className="relative">
                    <form onSubmit={handleSubmit}>
                        <TextareaAutosize
                            ref={inputRef}
                            value={value}
                            onChange={(e) => onChange(e.target.value)}
                            onKeyDown={handleKeyDown}
                            minRows={3}
                            className={cn(
                                "bg-transparent placeholder:text-white/50 text-sm text-white",
                                "resize-none focus:outline-none w-full max-h-24 lg:max-h-36 xl:max-h-48",
                                "pr-12 lg:pr-0" // Add right padding on mobile for the send button
                            )}
                            placeholder="Ask anything..."
                            disabled={loading}
                        />
                    </form>

                    {/* Mobile send button - absolute positioned to the right of input */}
                    <div className="absolute right-0 top-1/2 -translate-y-1/2 lg:hidden">
                        <SendButton />
                    </div>
                </div>

                {/* Desktop controls and send button */}
                <div className="hidden lg:flex flex-row items-center justify-between mt-4">
                    <div className="flex-1">
                        <Controls />
                    </div>
                    <SendButton />
                </div>
            </div>
        </div>
    );
};

export default EmptyChatMessageInput;