import React from 'react';
import { ArrowRight } from 'lucide-react';
import { cn } from 'utils/classnames';
import { Icebreaker } from 'api/generated-client';

interface IcebreakerCapsuleProps {
    icebreaker: Icebreaker;
    disabled?: boolean;
    onSelect: (icebreaker: Icebreaker) => void;
}

const IcebreakerCapsule = ({
    icebreaker,
    disabled = false,
    onSelect,
}: IcebreakerCapsuleProps) => {
    return (
        <button
            onClick={() => onSelect(icebreaker)}
            disabled={disabled}
            className={cn(
                // Base styles
                "group px-4 py-1.5 inline-flex items-center",
                "text-left transition-all duration-200",

                // Normal state
                "bg-dark-secondary border border-dark-200",
                "text-white/70 rounded-full",

                // Hover state
                "hover:bg-dark-100 hover:border-dark-100",
                "hover:text-white hover:shadow-sm",

                // Active state
                "active:scale-[0.98]",

                // Disabled state
                "disabled:opacity-50 disabled:cursor-not-allowed",
                "disabled:hover:bg-dark-secondary disabled:hover:border-dark-200"
            )}
        >
            <span className="text-sm font-medium">
                {icebreaker.title}
            </span>
            <ArrowRight
                size={13}
                className={cn(
                    "ml-2 flex-shrink-0",
                    "text-white/40 group-hover:text-white/70",
                    "transition-all duration-200",
                    "group-hover:translate-x-0.5"
                )}
            />
        </button>
    );
};

export default IcebreakerCapsule;