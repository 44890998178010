// src/utils/magicLink.ts
export const decodeMagicLink = (magiclink: string): { email: string; otp: string } => {
    try {
      // Remove any trailing = if present
      const cleanMagicLink = magiclink.replace(/=+$/, '');
      
      // Decode base64
      const decoded = atob(cleanMagicLink);
      
      // Split on the last occurrence of '/' to separate email and otp
      const lastSlashIndex = decoded.lastIndexOf('/');
      if (lastSlashIndex === -1) {
        throw new Error('Invalid magic link format');
      }
  
      const email = decoded.slice(0, lastSlashIndex);
      const otp = decoded.slice(lastSlashIndex + 1);
  
      if (!email || !otp) {
        throw new Error('Invalid magic link format');
      }
  
      return { email, otp };
    } catch (error) {
      console.error('Magic link decode error:', error);
      throw new Error('Invalid magic link');
    }
  };