import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import { App } from './App';

const RootWrapper = () => {
    return (
        <>
            {/* Fixed background */}
            <div
                className="fixed inset-0 w-full h-full bg-cover bg-center bg-no-repeat -z-10"
                style={{ backgroundImage: "url('/pr-background.jpeg')" }}
            />

            {/* Global overlay */}
            <div className="fixed inset-0 bg-black/50 backdrop-blur-sm -z-10" />

            {/* App content */}
            <App />
        </>
    );
};

// Get root element
const container = document.getElementById('root');
if (!container) {
    throw new Error('Failed to find root element');
}

// Create root and render app
const root = createRoot(container);
root.render(
    <React.StrictMode>
        <RootWrapper />
    </React.StrictMode>
);
