import React from 'react';
import { Dialog, DialogPanel, Transition, TransitionChild } from '@headlessui/react';
import { Brain, Database, X } from 'lucide-react';
import { Dropdown } from 'components/ui/Dropdown';
import { LLMModel, KnowledgeBase } from 'api/generated-client';
import { cn } from 'utils/classnames';

interface MobileControlsDialogProps {
    isOpen: boolean;
    onClose: () => void;
    models: LLMModel[];
    knowledgeBases: KnowledgeBase[];
    selectedModel: string;
    selectedKnowledgeBase: string;
    isUpdating: boolean;
    onModelChange: (modelId: string) => void;
    onKnowledgeBaseChange: (kbId: string) => void;
}

const MobileControlsDialog = ({
    isOpen,
    onClose,
    models,
    knowledgeBases,
    selectedModel,
    selectedKnowledgeBase,
    isUpdating,
    onModelChange,
    onKnowledgeBaseChange,
}: MobileControlsDialogProps) => {
    return (
        <Transition appear show={isOpen} as={React.Fragment}>
            <Dialog as="div" className="relative z-50" onClose={onClose}>
                <TransitionChild
                    as={React.Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black/30" />
                </TransitionChild>

                <div className="fixed inset-0">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <TransitionChild
                            as={React.Fragment}
                            enter="ease-out duration-200"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-100"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <DialogPanel className="w-full max-w-md transform rounded-2xl bg-dark-secondary p-6 text-left align-middle shadow-xl transition-all border border-dark-200">
                                <div className="flex items-center justify-between mb-6">
                                    <h3 className="text-lg font-medium text-white">
                                        Thread Settings
                                    </h3>
                                    <button
                                        onClick={onClose}
                                        className="text-white/70 hover:text-white transition-colors"
                                    >
                                        <X size={20} />
                                    </button>
                                </div>

                                <div className="space-y-6">
                                    <div className="space-y-2 relative z-10">
                                        <label className="text-sm text-white/70">Model</label>
                                        <Dropdown
                                            icon={<Brain size={16} />}
                                            value={selectedModel}
                                            onChange={onModelChange}
                                            options={models.map((model) => ({
                                                value: model.id ?? "",
                                                label: model.name ?? "",
                                                description: model.provider ?? "",
                                            }))}
                                            disabled={isUpdating}
                                            buttonClassName={cn(
                                                "w-full",
                                                isUpdating && "opacity-50 cursor-not-allowed"
                                            )}
                                        />
                                    </div>

                                    <div className="space-y-2 relative z-0">
                                        <label className="text-sm text-white/70">Knowledge Base</label>
                                        <Dropdown
                                            icon={<Database size={16} />}
                                            value={selectedKnowledgeBase}
                                            onChange={onKnowledgeBaseChange}
                                            options={knowledgeBases.map((kb) => ({
                                                value: kb.id ?? "",
                                                label: kb.name ?? "",
                                                description: kb.description ?? "",
                                            }))}
                                            disabled={isUpdating}
                                            buttonClassName={cn(
                                                "w-full",
                                                isUpdating && "opacity-50 cursor-not-allowed"
                                            )}
                                        />
                                    </div>
                                </div>

                                {isUpdating && (
                                    <div className="mt-4 text-sm text-white/70 flex items-center justify-center space-x-2">
                                        <div className="w-4 h-4 border-2 border-white/70 border-t-transparent rounded-full animate-spin" />
                                        <span>Updating settings...</span>
                                    </div>
                                )}
                            </DialogPanel>
                        </TransitionChild>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
};

export default MobileControlsDialog;