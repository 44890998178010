import { useState } from 'react';
import ConfirmationDialog from 'components/ui/ConfirmationDialog';

interface LogoutDialogProps {
    isOpen: boolean;
    onClose: () => void;
    onConfirm: () => void;
}

export const LogoutDialog = ({ isOpen, onClose, onConfirm }: LogoutDialogProps) => {
    const [isLoggingOut, setIsLoggingOut] = useState(false);

    const handleLogout = async () => {
        setIsLoggingOut(true);
        try {
            await onConfirm();
        } catch (error) {
            console.error('Failed to logout:', error);
            setIsLoggingOut(false);
        }
    };

    return (
        <ConfirmationDialog
            isOpen={isOpen}
            title="Confirm Logout"
            message="Are you sure you want to log out? You will need to log in again to access your chats."
            confirmLabel="Logout"
            isConfirming={isLoggingOut}
            confirmingLabel="Logging out..."
            onClose={onClose}
            onConfirm={handleLogout}
            isDanger
        />
    );
};