import React, { useState, useEffect } from 'react';
import { Search } from 'lucide-react';
import { useAuth } from 'context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { Icebreaker } from 'api/generated-client';
import IcebreakerCard from '../components/chat/IceBreakerCard';

const SearchPage = () => {
    const { apis } = useAuth();
    const navigate = useNavigate();
    const [icebreakers, setIcebreakers] = useState<Icebreaker[]>([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const loadIcebreakers = async () => {
            if (!apis?.knowledgeApi) return;

            try {
                setIsLoading(true);
                const response = await apis.knowledgeApi.knowledgeKnowledgeBasesList();

                if (response.data.results) {
                    // Collect all icebreakers from all knowledge bases
                    const allIcebreakers = response.data.results
                        .filter(kb => kb.icebreakers && kb.icebreakers.length > 0)
                        .flatMap(kb => kb.icebreakers || []);

                    setIcebreakers(allIcebreakers);
                }
            } catch (error) {
                console.error('Failed to load icebreakers:', error);
            } finally {
                setIsLoading(false);
            }
        };

        loadIcebreakers();
    }, [apis?.knowledgeApi]);

    const handleIcebreakerSelect = (icebreaker: Icebreaker) => {
        if (icebreaker.prompt === undefined) {
            return;
        }
        navigate(`/chat?prompt=${encodeURIComponent(icebreaker.prompt)}`);
    };

    return (
        <div className="min-h-screen bg-dark-primary">
            <div className="flex flex-col pt-4">
                <div className="flex items-center">
                    <Search />
                    <h1 className="text-3xl font-medium p-2 text-white">Discover</h1>
                </div>
                <hr className="border-t border-dark-200 my-4 w-full" />
            </div>

            {isLoading ? (
                <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4 p-4">
                    {[...Array(6)].map((_, i) => (
                        <div
                            key={i}
                            className="bg-dark-secondary rounded-lg aspect-[4/3] animate-pulse"
                        />
                    ))}
                </div>
            ) : (
                <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4 p-4 pb-24 lg:pb-8">
                    {icebreakers.map((icebreaker, index) => (
                        <div key={index} className="w-full">
                            <IcebreakerCard
                                icebreaker={icebreaker}
                                onSelect={handleIcebreakerSelect}
                            />
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default SearchPage;