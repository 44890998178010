import React from 'react';
import { BrowserRouter, Routes, Route, Navigate, Outlet } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
import { ProtectedRoute } from './components/auth/ProtectedRoute';
import { LoginPage } from './pages/LoginPage';
import { ChatPage } from './pages/ChatPage';
import SearchPage from './pages/SearchPage';
import LibraryPage from './pages/LibraryPage';
import { Sidebar } from './components/ui/SideBar';
import { ThemeProvider } from './context/ThemeContext';
import MagicLinkPage from 'pages/MagicLink';

export const App: React.FC = () => {
    return (
        <AuthProvider>
            <ThemeProvider>
                <BrowserRouter>
                    <Routes>
                        {/* Public routes */}
                        <Route path="/login" element={<LoginPage />} />

                        <Route path="/login/:token" element={<MagicLinkPage />} />

                        {/* Protected routes wrapped in Sidebar */}
                        <Route path="/" element={
                            <ProtectedRoute>
                                <Sidebar>
                                    <Outlet />
                                </Sidebar>
                            </ProtectedRoute>
                        }>
                            <Route path="chat" element={<ChatPage />} />
                            <Route path="chat/:threadId" element={<ChatPage />} />
                            <Route path="search" element={<SearchPage />} />
                            <Route path="library" element={<LibraryPage />} />
                            <Route index element={<Navigate to="/chat" replace />} />
                            <Route path="*" element={<Navigate to="/chat" replace />} />
                        </Route>
                    </Routes>
                </BrowserRouter>
            </ThemeProvider>
        </AuthProvider>
    );
};

export default App;