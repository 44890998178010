// src/pages/LoginPage.tsx
import { AuthFlow } from '../components/auth/AuthFlow';
import { useNavigate } from 'react-router-dom';

interface LoginPageProps {
    backgroundUrl?: string;
}

export const LoginPage: React.FC = () => {
    const navigate = useNavigate();

    return (
        <div className="flex items-center justify-center min-h-screen">
            <div className="w-full max-w-md mx-4">
                <AuthFlow
                    onSuccess={() => navigate('/chat')}
                    onError={(error) => console.error(error)}
                />
            </div>
        </div>
    );
};

