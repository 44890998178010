import React, { useState, useEffect } from 'react';
import EmptyChatMessageInput from './EmptyChatMessageInput';
import Icebreakers from './IceBreakers';
import { useAuth } from '../../context/AuthContext';
import { Icebreaker, KnowledgeBase, LLMModel } from 'api/generated-client';

interface EmptyChatProps {
    sendMessage: (message: string) => void;
    loading: boolean;
    selectedModel: string;
    selectedKnowledgeBase: string;
    onModelChange: (modelId: string) => void;
    onKnowledgeBaseChange: (kbId: string) => void;
}

const EmptyChat = ({
    sendMessage,
    loading,
    selectedModel,
    selectedKnowledgeBase,
    onModelChange,
    onKnowledgeBaseChange
}: EmptyChatProps) => {
    const { apis } = useAuth();
    const [models, setModels] = useState<LLMModel[]>([]);
    const [knowledgeBases, setKnowledgeBases] = useState<KnowledgeBase[]>([]);
    const [icebreakers, setIcebreakers] = useState<Icebreaker[]>([]);
    const [inputValue, setInputValue] = useState('');
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const loadData = async () => {
            if (!apis?.modelsApi || !apis?.knowledgeApi) return;
            setIsLoading(true);

            try {
                const [modelsResponse, kbResponse] = await Promise.all([
                    apis.modelsApi.modelsModelsList(),
                    apis.knowledgeApi.knowledgeKnowledgeBasesList()
                ]);

                if (modelsResponse.data.results) {
                    setModels(modelsResponse.data.results);
                    if (!selectedModel) {
                        const defaultModel = modelsResponse.data.results.find(model => model.is_default);
                        if (defaultModel?.id) {
                            onModelChange(defaultModel.id);
                        }
                    }
                }

                if (kbResponse.data.results) {
                    setKnowledgeBases(kbResponse.data.results);
                    const selectedKB = kbResponse.data.results.find(kb => kb.id === selectedKnowledgeBase);
                    if (selectedKB?.icebreakers) {
                        setIcebreakers(selectedKB.icebreakers);
                    }

                    if (!selectedKnowledgeBase) {
                        const defaultKB = kbResponse.data.results.find(kb => kb.is_default);
                        if (defaultKB?.id) {
                            onKnowledgeBaseChange(defaultKB.id);
                        }
                    }
                }
            } catch (error) {
                console.error('Failed to load models or knowledge bases:', error);
            } finally {
                setIsLoading(false);
            }
        };

        loadData();
    }, [apis?.modelsApi, apis?.knowledgeApi, selectedModel, selectedKnowledgeBase, onModelChange, onKnowledgeBaseChange]);

    const selectedKB = knowledgeBases.find(kb => kb.id === selectedKnowledgeBase);
    const kbTitle = selectedKB?.title || "Research begins here.";

    const handleIcebreakerSelect = (icebreaker: Icebreaker) => {
        let prompt = icebreaker.prompt;
        if (prompt === undefined) {
            return;
        }
        setInputValue(prompt);
        sendMessage(prompt);
    };

    return (
        <div className="relative">
            <div className="flex flex-col items-center justify-center min-h-screen max-w-screen-sm mx-auto p-2 space-y-8">
                <h2 className="text-white/70 text-3xl font-medium -mt-8 text-center">
                    {kbTitle}
                </h2>

                <div className="w-full">
                    <Icebreakers
                        icebreakers={icebreakers.slice(0, 4)}
                        isLoading={isLoading}
                        disabled={loading}
                        onSelect={handleIcebreakerSelect}
                    />
                </div>

                <EmptyChatMessageInput
                    sendMessage={sendMessage}
                    loading={loading}
                    models={models}
                    knowledgeBases={knowledgeBases}
                    selectedModel={selectedModel}
                    selectedKnowledgeBase={selectedKnowledgeBase}
                    onModelChange={onModelChange}
                    onKnowledgeBaseChange={onKnowledgeBaseChange}
                    value={inputValue}
                    onChange={setInputValue}
                />
            </div>
        </div>
    );
};

export default EmptyChat;