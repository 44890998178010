import React, { createContext, useContext, useState, useCallback } from 'react';
import { createAuthorizedApis, type AuthorizedApis } from '../utils/api';

interface AuthContextType {
    token: string | null;
    apis: AuthorizedApis | null;
    login: (token: string) => void;
    logout: () => void;
}

const AuthContext = createContext<AuthContextType | null>(null);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [token, setToken] = useState<string | null>(localStorage.getItem('authToken'));
    const [apis, setApis] = useState<AuthorizedApis | null>(
        token ? createAuthorizedApis(token) : null
    );

    const login = useCallback((newToken: string) => {
        localStorage.setItem('authToken', newToken);
        setToken(newToken);
        setApis(createAuthorizedApis(newToken));
    }, []);

    const logout = useCallback(() => {
        localStorage.removeItem('authToken');
        setToken(null);
        setApis(null);
    }, []);

    return (
        <AuthContext.Provider value={{ token, apis, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within AuthProvider');
    }
    return context;
};
