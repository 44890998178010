// src/components/chat/Chat.tsx
import { Fragment, useEffect, useRef } from 'react';
import MessageInput from './MessageInput';
import { Message } from '../../api/generated-client';
import MessageBox from './MessageBox';
import MessageBoxLoading from './MessageBoxLoading';

interface ChatProps {
  loading: boolean;
  messages: Message[];
  sendMessage: (message: string) => void;
  messageAppeared: boolean;
  rewrite: (messageId: string) => void;
}

const Chat = ({
  loading,
  messages,
  sendMessage,
  messageAppeared,
  rewrite,
}: ChatProps) => {
  const messageEnd = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    messageEnd.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  return (
    <div className="relative flex flex-col min-h-[calc(100vh-4rem)] lg:min-h-screen">
      {/* Messages Container */}
      <div className="flex-1 overflow-y-auto px-4 pb-40 lg:pb-32">
        <div className="max-w-3xl mx-auto w-full">
          {messages.map((msg, i) => {
            const isLast = i === messages.length - 1;

            return (
              <Fragment key={msg.id ?? i}>
                <MessageBox
                  message={msg}
                  loading={loading}
                  isLast={isLast}
                  onRewrite={() => rewrite(msg.id ?? '')}
                />
                {!isLast && msg.role === 'assistant' && (
                  <div className="h-px w-full bg-dark-secondary my-6" />
                )}
              </Fragment>
            );
          })}

          {loading && !messageAppeared && <MessageBoxLoading />}
          <div ref={messageEnd} />
        </div>
      </div>

      {/* Fixed Input Container */}
      <div className="fixed bottom-0 left-0 right-0 lg:left-20 bg-gradient-to-t from-dark-primary via-dark-primary/90 to-transparent pt-6">
        <div className="max-w-3xl mx-auto px-4 pb-24 lg:pb-8">
          <MessageInput
            loading={loading}
            sendMessage={sendMessage}
          />
        </div>
      </div>
    </div>
  );
};

export default Chat;