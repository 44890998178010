import { BookCopy } from 'lucide-react';
import { Fragment, useState } from 'react';
import { Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from '@headlessui/react';
import { MessageRelevantChunk } from '../../api/generated-client';
import Markdown from 'markdown-to-jsx';

interface SourceListProps {
    sources: MessageRelevantChunk[];
    className?: string;
}

export const SourceList = ({ sources, className = '' }: SourceListProps) => {
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [selectedContent, setSelectedContent] = useState<string | null>(null);
    const [dialogTitle, setDialogTitle] = useState('');
    const [showAllSources, setShowAllSources] = useState(false);

    const closeModal = () => {
        setIsDialogOpen(false);
        setSelectedContent(null);
        setShowAllSources(false);
        document.body.classList.remove('overflow-hidden-scrollable');
    };

    const openSourceContent = (title: string, content: string) => {
        setDialogTitle(title);
        setSelectedContent(content);
        setShowAllSources(false);
        setIsDialogOpen(true);
        document.body.classList.add('overflow-hidden-scrollable');
    };

    const openAllSources = () => {
        setDialogTitle('All Sources');
        setSelectedContent(null);
        setShowAllSources(true);
        setIsDialogOpen(true);
        document.body.classList.add('overflow-hidden-scrollable');
    };

    const handleSourceClick = (source: MessageRelevantChunk) => {
        if (source.metadata?.url) {
            window.open(source.metadata.url, '_blank');
        } else if (source.content) {
            openSourceContent(source.document_name || 'Source Content', source.content);
        }
    };

    if (!sources || sources.length === 0) {
        return null;
    }

    const getDomainFromUrl = (url: string) => {
        try {
            return url.replace(/.+\/\/|www.|\..+/g, '');
        } catch {
            return 'unknown';
        }
    };

    const SourceCard = ({ source, index }: { source: MessageRelevantChunk, index: number }) => (
        <div
            onClick={() => handleSourceClick(source)}
            className="bg-dark-100 hover:bg-dark-200 transition duration-200 
                     rounded-lg p-3 flex flex-col space-y-2 cursor-pointer
                     transform hover:scale-[1.02] active:scale-[0.98]"
        >
            <p className="text-white text-xs font-medium overflow-hidden whitespace-nowrap text-ellipsis">
                {source.document_name || 'Untitled Document'}
            </p>
            <div className="flex flex-row items-center justify-between">
                <div className="flex flex-row items-center space-x-1">
                    {source.metadata?.url && (
                        <>
                            <img
                                src={`https://s2.googleusercontent.com/s2/favicons?domain_url=${source.metadata.url}`}
                                width={16}
                                height={16}
                                alt="favicon"
                                className="rounded-lg h-4 w-4"
                            />
                            <p className="text-xs text-white/50 overflow-hidden whitespace-nowrap text-ellipsis">
                                {getDomainFromUrl(source.metadata.url)}
                            </p>
                        </>
                    )}
                    {source.chunk_number && (
                        <p className="text-xs text-white/50">
                            Page {source.chunk_number}
                        </p>
                    )}
                </div>
                <div className="flex flex-row items-center space-x-1 text-white/50 text-xs">
                    <div className="bg-white/50 h-[4px] w-[4px] rounded-full" />
                    <span>{index + 1}</span>
                </div>
            </div>
        </div>
    );

    return (
        <div className={`flex flex-col space-y-2 ${className}`}>
            <div className="flex flex-row items-center space-x-2 pb-3">
                <BookCopy className="text-white" size={20} />
                <h3 className="text-white font-medium text-xl">Sources</h3>
            </div>

            <div className="grid grid-cols-2 lg:grid-cols-4 gap-2">
                {sources.slice(0, 3).map((source, i) => (
                    <SourceCard key={i} source={source} index={i} />
                ))}

                {sources.length > 3 && (
                    <button
                        onClick={openAllSources}
                        className="bg-dark-100 hover:bg-dark-200 transition duration-200 
                                 rounded-lg p-3 flex flex-col justify-between text-white
                                 transform hover:scale-[1.02] active:scale-[0.98]"
                    >
                        <div className="flex flex-row items-center space-x-2">
                            {sources.slice(3, 6).map((source, i) => (
                                source.metadata?.url ? (
                                    <img
                                        key={i}
                                        src={`https://s2.googleusercontent.com/s2/favicons?domain_url=${source.metadata.url}`}
                                        width={16}
                                        height={16}
                                        alt="favicon"
                                        className="h-4 w-4 rounded-lg"
                                    />
                                ) : (
                                    <div
                                        key={i}
                                        className="bg-dark-200 h-4 w-4 rounded-md"
                                    />
                                )
                            ))}
                        </div>
                        <p className="text-white/50 text-xs">
                            View {sources.length - 3} more
                        </p>
                    </button>
                )}
            </div>

            <Transition appear show={isDialogOpen} as={Fragment}>
                <Dialog as="div" className="relative z-50" onClose={closeModal}>
                    <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4">
                            <TransitionChild
                                as={Fragment}
                                enter="ease-out duration-200"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-100"
                                leaveFrom="opacity-100 scale-200"
                                leaveTo="opacity-0 scale-95"
                            >
                                <DialogPanel className="w-full max-w-md transform rounded-2xl 
                                                      bg-dark-secondary border border-dark-200 
                                                      p-6 text-left align-middle shadow-xl transition-all">
                                    <DialogTitle className="text-lg font-medium leading-6 text-white mb-4">
                                        {dialogTitle}
                                    </DialogTitle>

                                    {selectedContent ? (
                                        <div className="prose prose-invert max-w-none">
                                            <Markdown>{selectedContent}</Markdown>
                                        </div>
                                    ) : showAllSources ? (
                                        <div className="grid grid-cols-2 gap-2 overflow-auto max-h-[300px] mt-2 pr-2">
                                            {sources.map((source, i) => (
                                                <SourceCard key={i} source={source} index={i} />
                                            ))}
                                        </div>
                                    ) : null}
                                </DialogPanel>
                            </TransitionChild>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </div>
    );
};