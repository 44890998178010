// src/components/auth/EmailForm.tsx
import React from 'react';

interface EmailFormProps {
    onSubmit: (email: string) => void;
    isLoading: boolean;
    error?: string | null;
}

export const EmailForm: React.FC<EmailFormProps> = ({
    onSubmit,
    isLoading,
    error,
}) => {
    const [email, setEmail] = React.useState('');

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        onSubmit(email);
    };

    return (
        <form onSubmit={handleSubmit} className="space-y-4">
            <div>
                <label htmlFor="email" className="block text-sm font-medium text-white/70 mb-2">
                    Email Address
                </label>
                <input
                    id="email"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="w-full px-4 py-3 bg-dark-100/50 border border-dark-200 text-white rounded-lg
                    focus:outline-none focus:ring-2 focus:ring-blue-500/50 focus:border-transparent
                    placeholder:text-white/30 transition duration-200"
                    placeholder="Enter your email"
                    required
                    disabled={isLoading}
                />
            </div>

            {error && (
                <div className="bg-red-500/10 border border-red-500/50 text-red-200 px-4 py-3 rounded-lg">
                    {error}
                </div>
            )}

            <button
                type="submit"
                disabled={isLoading}
                className="w-full bg-blue-500 hover:bg-blue-600 focus:bg-blue-600 text-white py-3 px-4 rounded-lg
                 font-medium transition duration-200 focus:outline-none focus:ring-2 focus:ring-blue-500/50
                 focus:ring-offset-2 focus:ring-offset-dark-100 disabled:opacity-50 disabled:cursor-not-allowed"
            >
                {isLoading ? (
                    <div className="flex items-center justify-center space-x-2">
                        <div className="w-5 h-5 border-2 border-white/30 border-t-white rounded-full animate-spin" />
                        <span>Sending code...</span>
                    </div>
                ) : (
                    'Continue with Email'
                )}
            </button>
        </form>
    );
};
