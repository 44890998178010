import { Clock, Settings2, SquarePen, Trash } from "lucide-react";
import { useState, useEffect, useCallback } from "react";
import { cn, formatTimeDifference } from "utils/classnames";
import { Title } from "./Title";
import { Controls } from "./Controls";
import MobileControlsDialog from "./MobileControlsDialog";
import type { NavBarProps } from "./types";
import { KnowledgeBase, LLMModel } from "api/generated-client";
import { useAuth } from "context/AuthContext";
import { LogoutDialog } from "./LogoutDialog";
import { useNavigate } from "react-router-dom";
import ConfirmationDialog from "components/ui/ConfirmationDialog";
import UserProfileMenu from "components/ui/UserProfileMenu";

interface ExtendedNavBarProps extends NavBarProps {
  onThreadListToggle?: () => void;
}

export const NavBar = ({ chatId, messages, className, onThreadListToggle }: ExtendedNavBarProps) => {
  // State management
  const { apis, logout } = useAuth();
  const navigate = useNavigate();
  const [title, setTitle] = useState("");
  const [timeAgo, setTimeAgo] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [newTitle, setNewTitle] = useState("");
  const [isUpdating, setIsUpdating] = useState(false);
  const [showMobileControls, setShowMobileControls] = useState(false);
  const [models, setModels] = useState<LLMModel[]>([]);
  const [knowledgeBases, setKnowledgeBases] = useState<KnowledgeBase[]>([]);
  const [selectedModel, setSelectedModel] = useState<string>("");
  const [selectedKnowledgeBase, setSelectedKnowledgeBase] = useState<string>("");
  const [showLogoutDialog, setShowLogoutDialog] = useState(false);

  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const handleDelete = async () => {
    if (!chatId || !apis?.chatsApi) return;
    setIsDeleting(true);
    try {
      await apis.chatsApi.chatsThreadsDestroy(chatId);
      navigate('/chat');
    } catch (error) {
      console.error('Failed to delete thread:', error);
      setIsDeleting(false);
      setShowDeleteConfirm(false);
    }
  };
  // Load thread data, models, and knowledge bases
  useEffect(() => {
    const loadData = async () => {
      if (!apis?.modelsApi || !apis?.knowledgeApi || !apis?.chatsApi || !chatId) return;

      try {
        const [threadResponse, modelsResponse, kbResponse] = await Promise.all([
          apis.chatsApi.chatsThreadsRetrieve(chatId),
          apis.modelsApi.modelsModelsList(),
          apis.knowledgeApi.knowledgeKnowledgeBasesList()
        ]);

        // Set models and knowledge bases
        if (modelsResponse.data.results) {
          setModels(modelsResponse.data.results);
        }

        if (kbResponse.data.results) {
          setKnowledgeBases(kbResponse.data.results);
        }

        // Set thread data
        if (threadResponse.data) {
          const thread = threadResponse.data;
          setTitle(thread.title || '');
          setNewTitle(thread.title || '');

          // Only set model and KB if they exist in the thread
          if (thread.model) {
            setSelectedModel(thread.model);
          }

          if (thread.knowledge_base) {
            setSelectedKnowledgeBase(thread.knowledge_base);
          }
        }
      } catch (error) {
        console.error('Failed to load data:', error);
      }
    };

    loadData();
  }, [chatId, apis?.modelsApi, apis?.knowledgeApi, apis?.chatsApi]);

  // Update thread settings
  const updateThreadSettings = useCallback(async (updates: {
    model?: string;
    knowledge_base?: string;
    title?: string;
  }) => {
    if (!chatId || !apis?.chatsApi) return;

    setIsUpdating(true);
    try {
      const response = await apis.chatsApi.chatsThreadsPartialUpdate(chatId, updates);
      if (response.data) {
        if (updates.title) {
          setTitle(updates.title);
          setIsEditing(false);
        }
      }
    } catch (error) {
      console.error("Failed to update thread:", error);
    } finally {
      setIsUpdating(false);
    }
  }, [chatId, apis?.chatsApi]);

  // Update timeAgo
  useEffect(() => {
    if (messages.length > 0 && messages[0].created) {
      const interval = setInterval(() => {
        const messageDate = new Date(messages[0].created!);
        const newTimeAgo = formatTimeDifference(new Date(), messageDate);
        setTimeAgo(newTimeAgo);
      }, 60000); // Update every minute

      // Initial set
      const messageDate = new Date(messages[0].created);
      const newTimeAgo = formatTimeDifference(new Date(), messageDate);
      setTimeAgo(newTimeAgo);

      return () => clearInterval(interval);
    }
  }, [messages]);

  const handleTitleSubmit = useCallback(async () => {
    if (!newTitle?.trim()) return;
    await updateThreadSettings({ title: newTitle });
  }, [newTitle, updateThreadSettings]);

  const handleModelChange = useCallback(async (modelId: string) => {
    setSelectedModel(modelId);
    await updateThreadSettings({ model: modelId });
  }, [updateThreadSettings]);

  const handleKnowledgeBaseChange = useCallback(async (kbId: string) => {
    setSelectedKnowledgeBase(kbId);
    await updateThreadSettings({ knowledge_base: kbId });
  }, [updateThreadSettings]);

  return (
    <>
      <div className="fixed top-0 left-0 right-0 z-40 bg-dark-primary border-b border-dark-200">
        {/* Main Navbar */}
        <div className={cn(
          "h-16 w-full lg:pl-20",
          className
        )}>
          <div className="h-full mx-auto px-4 lg:px-8 
                         grid grid-cols-3 items-center">
            {/* Left Section */}
            <div className="flex items-center">
              <button
                onClick={() => { navigate("/chat") }}
                className="lg:hidden text-white/70 hover:text-white p-2 -ml-2"
              >
                <SquarePen size={20} />
              </button>

              <div className="hidden lg:flex items-center space-x-2 text-white/70">
                <Clock size={17} />
                <span className="text-sm whitespace-nowrap">{timeAgo} ago</span>
              </div>
            </div>

            {/* Center Section - Title */}
            <Title
              title={title}
              isEditing={isEditing}
              newTitle={newTitle}
              isUpdating={isUpdating}
              onTitleChange={setNewTitle}
              onTitleSubmit={handleTitleSubmit}
              onEditStart={() => setIsEditing(true)}
              onEditCancel={() => {
                setNewTitle(title);
                setIsEditing(false);
              }}
            />

            {/* Right Section */}
            <div className="flex items-center justify-end space-x-2">
              <Controls
                className="hidden xl:flex"
                models={models}
                knowledgeBases={knowledgeBases}
                selectedModel={selectedModel}
                selectedKnowledgeBase={selectedKnowledgeBase}
                isUpdating={isUpdating}
                onModelChange={handleModelChange}
                onKnowledgeBaseChange={handleKnowledgeBaseChange}
              />

              {/* Mobile Controls and Logout */}
              <div className="flex items-center space-x-2">
                {/* Delete Button - Only show if chatId exists */}
                {chatId && (
                  <button
                    onClick={() => setShowDeleteConfirm(true)}
                    className="text-red-400 hover:text-red-500 transition duration-200 p-2
                             flex items-center space-x-2 rounded-lg hover:bg-dark-100"
                  >
                    <Trash size={16} />
                  </button>
                )}
                {/* Mobile Controls */}
                <button
                  onClick={() => setShowMobileControls(!showMobileControls)}
                  className="xl:hidden text-white/70 hover:text-white p-2"
                >
                  <Settings2 size={20} />
                </button>
                <div className="lg:hidden">
                  <UserProfileMenu placement="navbar" />
                </div>



                <ConfirmationDialog
                  isOpen={showDeleteConfirm}
                  title="Delete Thread"
                  message="Are you sure you want to delete this thread? This action cannot be undone."
                  confirmLabel="Delete"
                  isConfirming={isDeleting}
                  confirmingLabel="Deleting..."
                  onClose={() => setShowDeleteConfirm(false)}
                  onConfirm={handleDelete}
                  isDanger
                />
              </div>
            </div>
          </div>
        </div>


      </div>
      <MobileControlsDialog
        isOpen={showMobileControls}
        onClose={() => setShowMobileControls(false)}
        models={models}
        knowledgeBases={knowledgeBases}
        selectedModel={selectedModel}
        selectedKnowledgeBase={selectedKnowledgeBase}
        isUpdating={isUpdating}
        onModelChange={handleModelChange}
        onKnowledgeBaseChange={handleKnowledgeBaseChange}
      />

      {/* Logout Dialog */}
      <LogoutDialog
        isOpen={showLogoutDialog}
        onClose={() => setShowLogoutDialog(false)}
        onConfirm={() => {
          setShowLogoutDialog(false);
          logout();
        }}
      />

      {/* Add a spacer div to push content below the fixed navbar */}
      <div className="h-16"></div>
    </>
  );
};

export default NavBar;